import French from '../data/french.json'
import English from '../data/english.json'
import { SET_LANG } from '../data/actions_types'

const initial = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'eng'

// eslint-disable-next-line import/no-anonymous-default-export
export default (lang = initial === 'eng' ? English : French, action) => {
    switch (action.type) {
        case SET_LANG:
            if (action?.payload === 'fr') {
                localStorage.setItem('lang', 'fr')
                window.location.reload()
                return lang
            }

            localStorage.setItem('lang', 'eng')
            window.location.reload()
            return lang
        default:
            return lang
    }
}