import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAlert } from '../../../actions/alert'

const PartTwo = ({ step, setStep, setResults }) => {
    const dispatch = useDispatch()
    
    const lang = useSelector((state) => state.lang)

    const [selected, setSelected] = useState(null)

    const handleNotReady = () => {
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[17] }))
    }

    const next = () => {
        // add point to selected color
        setResults(prev => {
            const old = JSON.parse(JSON.stringify(prev))
            old.colors[selected?.color]++

            return old
        })

        // reset for next block
        setSelected(null)

        // set step to x.5
        setStep(prev => prev + 0.5)
    }

    return (
        <>
        {(step === 9 || step === 9.5) 
        ?
        <div className={`part-page ${step % 1 !== 0 ? 'out' : ''}`}>
            <h1>{lang?.kw?.part} <span>2</span></h1>

            <p>{lang?.color_test?.explanations}</p>

            <button 
                className='border-button orange min'
                onClick={() => setStep(prev => prev + 0.5)}
            >
                {lang?.buttons?.got_it}
            </button>
        </div>
        :
        <div className={`part two ${step % 1 !== 0 ? 'out' : ''} ${step >= 27 ? 'animates' : ''}`}> 
            <h2>{step < 27 ? lang?.color_test?.header_titles?.[0] : lang?.color_test?.header_titles?.[Math.floor(step) - 26]}</h2>

            <button 
                className={`next-button border-button ${selected ? 'orange' : ''}`} 
                onClick={selected ? next : handleNotReady}
            >
                {lang?.buttons?.next}
            </button>

            <main>
                <div className="inner">
                    <button 
                        className={selected?.ind === 1 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 1, color: lang?.color_test?.choices?.[Math.floor(step) - 10]?.[0]?.color })}
                    >
                        <h3>{lang?.color_test?.choices?.[Math.floor(step) - 10]?.[0]?.text}</h3>
                    </button>
                    <button 
                        className={selected?.ind === 2 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 2, color: lang?.color_test?.choices?.[Math.floor(step) - 10]?.[1]?.color })}
                    >
                        <h3>{lang?.color_test?.choices?.[Math.floor(step) - 10]?.[1]?.text}</h3>
                    </button>
                    <button 
                        className={selected?.ind === 3 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 3, color: lang?.color_test?.choices?.[Math.floor(step) - 10]?.[2]?.color })}
                    >
                        <h3>{lang?.color_test?.choices?.[Math.floor(step) - 10]?.[2]?.text}</h3>
                    </button>
                    <button 
                        className={selected?.ind === 4 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 4, color: lang?.color_test?.choices?.[Math.floor(step) - 10]?.[3]?.color })}
                    >
                        <h3>{lang?.color_test?.choices?.[Math.floor(step) - 10]?.[3]?.text}</h3>
                    </button>
                </div>
            </main>
        </div>
        }
        </>
    )
}

export default PartTwo
