import React, { useState } from 'react'
import './styles.css'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { dna } from '../../../../utilities/formats'

const OrgCell = ({ sports }) => {
    const navigate = useNavigate()

    const lang = useSelector((state) => state.lang)
    const org = useSelector((state) => state.org)

    return (
        <div className='org-cell'>
            <h2>{lang?.kw?.org}</h2>

            {org
            ?
            <Link to='settings#sports'>
                <h3>{org?.name}</h3>
                <p>{lang?.kw?.manage} {'>'}</p>
            </Link>
            :
            <Link to='settings#sports' className="edit-overlay">
                {lang?.kw?.join_your_org}
            </Link>
            }
        </div>
    )
}


export default OrgCell
