import React, { useEffect, useState, useRef } from "react";
import "./styles.css";

import notVisible from './notVisible.png'
import isVisible from './visible.png'

const Input = ({ id, label, optional, type, defaultValue, disabled, classNames, onChange, reset, ...rest }) => {
  const input = useRef(null)

  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(type === 'password' ? false : true)

  const handleFocusLoss = (e) => {
    if (e.target.value === "" || e.target.value === null) setDone(false);
    else setDone(true);
  };

  const handleVisiblity = (e) => {
    setVisible((prev) => !prev)
  }

  useEffect(() => {
    if (input.current.value.trim().length > 0) setDone(true)
    if (type === 'date' && defaultValue !== 'NaN-NaN-NaN') {
      input.current.value = defaultValue
      setDone(true)
    }
  }, [defaultValue])

  // reset to default value
  useEffect(() => {
    if (reset) {
      if (reset.reset === true) {
        if (defaultValue) {
          input.current.value = defaultValue
        } else {
          input.current.value = null
          setDone(false)
        }

        reset.setReset(false)
      }
    }
  }, [reset])

  return (
    <div className={`input-wrap ${done ? "done" : ""} ${classNames}`} style={type === 'date' ? { '--calendar-input-image': `url('${process.env.PUBLIC_URL}/calendar_icon.png')` } : {}}>
      <input
        ref={input}
        type={visible ? type === 'password' ? 'text' : type : 'password'}
        id={id}
        name={id}
        onFocus={() => setDone(true)}
        onBlur={handleFocusLoss}
        onChange={onChange ? onChange : () => {}}
        required={optional ? false : true}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={id}>{label}</label>
      {type === 'password' && 
        <div className={`toggle ${visible ? 'visible' : 'invisible'}`} onClick={handleVisiblity}>
          <img src={isVisible} alt='visible'/>
          <img src={notVisible} alt='not visible'/>
        </div>
      }
    </div>
  );
};

export default Input;
