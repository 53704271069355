import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WebFont from "webfontloader";

// components
// import AppLoader from './components/loaders/AppLoader/AppLoader'
import MainNav from "./components/navbars/MainNav/MainNav";
import AlertModal from "./components/modals/AlertModal/AlertModal";
import Home from "./components/pages/Home/Home";
import Masks from "./components/pages/Masks/Masks";
import ContactUs from "./components/pages/ContactUs/ContactUs";
import Auth from "./components/pages/Auth/Auth";
import Verify from './components/pages/Auth/Verify/Verify'
import Profile from './components/pages/Profile/Profile'
import PageNotFound from './components/pages/PageNotFound/PageNotFound'
import ProfileNav from "./components/navbars/ProfileNav/ProfileNav";
import Settings from './components/pages/Profile/Settings/Settings'
import AddSport from "./components/pages/Admin/AddSport";
import Test from "./components/pages/Test/Test";
import Intro from "./components/pages/Test/Intro";
import PartOne from "./components/pages/Test/PartOne";
import PartTwo from "./components/pages/Test/PartTwo";
import PartThree from "./components/pages/Test/PartThree";
import Results from "./components/pages/Test/Results";
import OrgAdmin from './components/pages/OrgAdmin/OrgAdmin'
import LangModal from "./components/modals/LangModal/LangModal";
import ForgotPassword from "./components/pages/Auth/Password/ForgotPassword";
import ResetPassword from "./components/pages/Auth/Password/ResetPassword";

import { verifyTokenValidity } from "./actions/auth";
import { setAlert } from "./actions/alert";

const App = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  
  const lang = useSelector((state) => state.lang)
  const nav = useSelector((state) => state.nav)
  const alert = useSelector((state) => state.alert)
  const auth = useSelector((state) => state.auth)

  const [langModal, setLangModal] = useState(false)

  // on session expiration
  const onSessExp = () => {
    if (location.pathname !== '/auth') {
      setTimeout(
        () => dispatch(
          setAlert({ status: 'open', message: lang?.errors?.[18], onClose: () => navigate('/auth#login') })
        )
      , 2300)
    }
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins:300,400,500,600,700,800,900']
      }
    });
  }, []);

  useEffect(() => {
    // verify token validity
    if (auth?.user) {
      dispatch(verifyTokenValidity(auth?.token, onSessExp))
    }

    // verify language selection
    if (!localStorage.getItem('lang')) setTimeout(() => setLangModal(true), 1500)
  }, [])

  return (
    <>        
      {nav?.nav === 'main-nav' && nav?.currentPage !== '404' && nav?.currentPage !== 'password' && <MainNav />}
      {nav?.nav === 'profile-nav' && <ProfileNav />}
      {alert?.status === 'open' && <AlertModal {...alert}/>}
      {langModal && <LangModal closeModal={() => setLangModal(false)} />}

      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/masks" element={<Masks />} />
        <Route path="/store" />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/auth" element={<Auth />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="/profile" element={!auth.logged ? <Navigate to='/auth' replace /> : auth?.user?.acc_type === 'admin' ? <Navigate to='/admin' replace />  : <Profile />}>
          <Route path="settings" element={<Settings/>} />
        </Route>
        <Route path="/admin" element={<OrgAdmin />} />

        <Route path="/take-the-test" exact element={<Test />}>
          <Route index element={<Intro />}/>
          <Route path="part-one" element={<PartOne />}/>
          <Route path="part-two" element={<PartTwo />}/>
          <Route path="part-three" element={<PartThree />}/>
          <Route path="results" element={<Results />}/>
        </Route>

        <Route path="/super-admin/add-sport" element={<AddSport />} />

        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </>
  );
}

export default App;