import * as actions from '../data/actions_types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (teams = [], action) => {
    let arr
    switch (action.type) {
        case actions.CREATE_TEAM:
            arr = teams
            arr.push(action?.payload?.team)
            return arr
        case actions.SET_TEAMS:
            return action?.payload
        case actions.SET_TEAM:
            arr = Array.from(teams)
            const ind = arr.findIndex(t => t._id === action?.payload?._id)
            if (ind >= 0) arr.splice(ind, 1, action?.payload)
            else arr.push(action?.payload)
            return arr
        case actions.LEAVE_TEAM:
            arr = Array.from(teams)
            const ind2 = arr.findIndex(t => t._id === action?.payload?.teamId)
            arr.splice(ind2, 1)
            console.log('removed 1 team from teams array at index: ', ind2)
            return arr
        default:
            return teams
    }
}