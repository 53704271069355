import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Results = ({ results }) => {
    const lang = useSelector((state) => state.lang)

    useEffect(() => {
        sessionStorage.removeItem('test-step')
        sessionStorage.removeItem('test-results')
    }, [])

    return (
        <div className='part-page'>
            <h1>{lang?.kw?.all_done}</h1>
            <p>{lang?.p?.test_results}</p>
            <Link className='border-button orange' to='/profile#masks' >{lang?.kw?.go_to_profile}</Link>
        </div>
    )
}

export default Results
