import React, { useEffect, useState } from 'react'
import './styles.css'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import xIcon from '../../../assets/images/icons/orangeXIcon.png'
import TitleCarousel from './TitleCarousel/TitleCarousel'

import * as API from '../../../api/index'
import InputRoulette from './InputRoulette'
import { setAlert } from '../../../actions/alert'

const SportsModal = ({ close, mod }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const lang = useSelector((state) => state.lang)
    const eng = useSelector((state) => state.lang.name) === 'eng' ? true : false

    const [sports, setSports] = useState(null)
    const [ddSports, setDDSports] = useState(null)
    const [selected, setSelected] = useState(null)
    const [step, setStep] = useState(0)

    const cancel = () => {
        close()
        navigate('settings#sports')
    }
    
    useEffect(() => {
        fetchSports()
    }, [])

    const fetchSports = async () => {
        try {
            const { data } = await API.fetchSports()

            const arr = []
            for (let i = 0; i < data?.length || 0; i++) {
                arr[i] = { value: data?.[i]?._id, text: eng ? data?.[i]?.name_eng : data?.[i]?.name_fr }
            }

            setDDSports(arr)
            setSports(data)
        } catch (error) {
            console.log(error)
        }
    }

    const prev = () => {
        setStep(prev => prev > 0 ? prev - 1 : prev)
    }

    const next = (plus) => {
        plus = isNaN(plus) ? 1 : plus
        setStep(prev => prev < 4 ? prev + (plus ? plus : 1) : prev)
    }

    const alertSelectSport = () => {
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[14] }))
    }

    return (
        <div className='sports-modal-wrap'>
            <div className="sports-modal">
                <button className="close" onClick={cancel}>
                    <p>{lang?.buttons?.close}</p>
                    <img src={xIcon} alt="x" />
                </button>

                <TitleCarousel data={lang?.h?.add_sport_form} i={step} />

                <InputRoulette selected={selected} setSelected={setSelected} sports={sports} ddSports={ddSports} next={next} i={step} mod={mod} close={close} />

                <div className="controls">
                    <button onClick={prev}>{'<'} {lang?.buttons?.back}</button>
                    <p>{`${step + 1} ${eng ? 'of' : 'de'} 5`}</p>
                    <button onClick={selected ? next : alertSelectSport}>{lang?.buttons?.next} {'>'}</button>
                </div>
            </div>
        </div>
    )
}

export default SportsModal
