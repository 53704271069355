import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import { useDetectClickOutside } from 'react-detect-click-outside'

import arrowDown from './arrowDownOrange.png'

const DDInput = ({ id, label, options, onOptionClick, other, allowType, simple, defaultValue, setValue, reset, opensUp, ...rest }) => {
  const ref = useDetectClickOutside({ onTriggered: (e) => handleFocusLoss(e) });
  const input = useRef()

  const [open, setOpen] = useState(false)
  const [done, setDone] = useState(false)
  const [filtered, setFiltered] = useState(options)
  const [realValue, setRealValue] = useState('')
  const [up, setUp] = useState(opensUp ? true : false)

  // handle focus
  const handleFocus = () => {
    const dd = document.getElementById(id)
    if (dd.getBoundingClientRect()) {
      let h = window.innerHeight
      let t = dd?.getBoundingClientRect()?.top
      let b = dd?.getBoundingClientRect()?.bottom
      if (h - b > 200) {
        setUp(false)
      } else if (h - b < 200 && t > 200) {
        setUp(true)
      }
    }
    setOpen(true)
    setDone(true)
  }

  useEffect(() => {
    setDone(false)
  }, [])

  // handle focus loss
  const handleFocusLoss = () => {
    if (!input.current?.value) setDone(false);
    else setDone(true);
    setOpen(false)
  };

  // on key down
  const handleKeyDown = (e) => {
    if (!allowType) e.preventDefault()
  }

  // on click
  const handleOptionClick = (e) => {
    e.preventDefault()

    setRealValue(e?.target?.dataset?.value)
    input.current.value = e.target?.dataset?.text
    setOpen(false)

    if (onOptionClick) onOptionClick(e) 
  }

  const toggleDD = () => {
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    setFiltered(options)
  }, [options])

  useEffect(() => {
    if (defaultValue) {
      input.current.value = defaultValue
      setDone(true)
    }
  }, [defaultValue])

  useEffect(() => {
    if (reset) {
      if (reset.reset === true) {
        if (defaultValue) {
          input.current.value = defaultValue
        } else {
          input.current.value = null
          setDone(false)
        }

        reset.setReset(false)
      }
    }
  }, [reset])


  return (
    <div className={`dd ${open ? 'open' : ''} ${up ? 'up' : 'down'} ${done ? 'done' : ''}`} ref={ref} >
      <div className="dd-header">
        <input type="text" 
          id={id} 
          name={id} 
          data-value={realValue}
          onClick={handleFocus}
          onKeyDown={handleKeyDown}
          ref={input}
          defaultValue={null}
          {...rest}
          />
        <label htmlFor={id}>{label}</label>
        <img src={arrowDown} alt="arrow" onClick={toggleDD}/>
      </div>
      <ul className='dd-body'>
        {filtered && filtered.map((el, ind) => {
          return (
            !simple ?
              <li key={ind}><button data-value={el.value} data-text={el.text} onClick={handleOptionClick}>{el.text}</button></li>
            :
              <li key={ind}><button data-value={el} data-text={el} onClick={handleOptionClick}>{el}</button></li>
          )
        })}
      </ul>
    </div>
  )
}

export default DDInput