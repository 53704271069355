import * as actions from '../data/actions_types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (profile = {}, action) => {
    switch (action.type) {
        case actions.SET_PROFILE:
            return action?.payload
        case actions.SET_PROFILE_PICTURE:
            return { ...profile, picture: action?.payload?.picture }
        default:
            return profile
    }
}