import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import RangeInput from '../../tools/RangeInput/RangeInput'

import { setAlert } from '../../../actions/alert'

const initial_masks = { captain: 0, soldier: 0, maven: 0, engineer: 0, strategist: 0, player: 0, driven: 0 }

const PartOne = ({ step, setStep, setResults }) => {
    const dispatch = useDispatch()
    
    const lang = useSelector((state) => state.lang)
    const main = useRef()
    
    const [masks, setMasks] = useState(initial_masks)
    const [ready, setReady] = useState(false)
    const [reset, setReset] = useState(false)

    const handleSelection = (value, mask) => {
        const newMasks = { ...masks, [mask]: value }
        setMasks(newMasks)

        // check if ready
        let scores = [0, 1, 3, 5, 9, 13, 18]
        let found = 0
        scores.forEach(value => {
            for (const key in newMasks) {
                if (newMasks?.[key] === value) {
                    found++
                    break
                }
            }
        })

        return setReady(found === 7 ? true : false)
    }

    const next = () => {
        // add selected to results score
        setResults(prev => {
            // update results with block's results
            const old = JSON.parse(JSON.stringify(prev))
            for (const key in masks) {
                old.masks[key] += masks[key]
            }

            return old
        })

        // trigger selected values reset
        setMasks(JSON.parse(JSON.stringify(initial_masks)))
        setReset(true)
        setReady(false)
        main.current.scrollTo({ top: 0, behavior: 'smooth' })

        // set step to x.5
        setStep(prev => prev + 0.5)
    }

    const handleNotReady = () => {
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[16] }))
    }



    return (
        <>
        {(step === 1 || step === 1.5) 
        ?
        <div className={`part-page ${step === 1.5 ? 'out' : ''} ${step === 2 ? 'first-in' : ''}`}>
            <h1>{lang?.kw?.part} <span>1</span></h1>

            <p>{lang?.mask_test?.explanations}</p>

            <button 
                className='border-button orange min'
                onClick={() => setStep(prev => prev + 0.5)}
            >
                {lang?.buttons?.got_it}
            </button>
        </div>
        :
        <div className={`part one ${step % 1 !== 0 ? 'out' : ''} ${step === 8.5 ? 'last-out' : ''}`}>

            <h2>{lang?.mask_test?.header_title}</h2>

            <button 
                className={`next-button border-button ${ready ? 'orange' : ''}`} 
                onClick={ready ? next : handleNotReady}
            >
                {lang?.buttons?.next}
            </button>

            <main ref={main} className='padded-scrollbar thin'>
                <ul>
                    {lang?.mask_test?.questions?.[Math.floor(step) - 2]?.map((question, ind) => {
                        return (
                            <li className={ind} key={ind}>
                                <h3>{question?.text}</h3>

                                <RangeInput i={ind} mask={question?.mask} onSelection={handleSelection} reset={{ state: reset, set: setReset }}/>                              
                            </li>
                        )
                    })}

                    <div className="line v-line"></div>
                </ul>
            </main>
        </div>
        }
        </>
    )
}

export default PartOne
