import React, { useEffect, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { setNav } from '../../../../actions/nav'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { API } from '../../../../api' 
import { setAlert } from '../../../../actions/alert'

import logo from '../../../../assets/images/logoSmall.png'

import Input from '../../../tools/Input/Input'
import StateButton from '../../../tools/StateButton/StateButton'

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const lang = useSelector((state) => state.lang)

    const { token } = useParams()

    const [state, setState] = useState('initial')

    const handleSendReset = async (e) => {
        e.preventDefault()

        const pass = document.getElementById('pass')?.value
        if (!pass) return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4]}))

        const confirm = document.getElementById('confirm-password')?.value
        if (!confirm) return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4]}))
        
        if (pass !== confirm) return dispatch(setAlert({ status: 'open', message: lang?.errors?.[0]}))

        setState('loading')

        try {
            const { data } = await API.post('/user/reset-password', { password: pass, token })
            setState('success')
            console.log(data)
            dispatch(setAlert({ status: 'open', message: `${lang?.messages?.[data?.message_id]}`, onClose: () => navigate('/auth#login')}))
        } catch (error) {
            setState('initial')
            const error_id = error?.response?.data?.error_id
            dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
        }
    }

    useEffect(() => {
        dispatch(setNav('password'))
    }, [])
    
    return (
        <div id='password-page'>
            <Link to='/'><img src={logo} alt="athlete-x logo" /></Link>

            <main>
                <h1>{lang?.kw?.reset_password}</h1>
                <form onSubmit={handleSendReset}>
                    <Input type='password' id='pass' label={lang?.inputs?.new_password} />
                    <Input type='password' id='confirm-password' label={lang?.inputs?.confirm_password}/>
                    <StateButton state={state} success={lang?.kw?.send_again} initial={lang?.kw?.reset_password} orange />
                </form>
            </main>

            <p className="copyrights">{lang?.p?.copyrights}</p>
        </div>
    )
}

export default ResetPassword
