import React from 'react'
import './styles.css'

import roundLoader from '../../../assets/images/icons/roundLoader.png'

const StateButton = ({ initial, success, onClick, state, orange, ...rest }) => {

    return (
        <div className={`state-button-wrap ${state === 'loading' ? 'loading' : ''} ${state === 'sucess' ? 'success' : ''}`}>
            <button {...rest} className={`border-button full ${orange ? 'orange' : ''}`} onClick={onClick}>{state === 'success' ? success : initial }</button>
            {state === 'loading' && <img src={roundLoader} alt='Loading Spinner' />}
        </div>
    )
}

export default StateButton