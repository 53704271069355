import React, { useEffect, useState } from 'react'
import './styles.css'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import Input from '../../tools/Input/Input'
import StateButton from '../../tools/StateButton/StateButton'

import { setAlert } from '../../../actions/alert'
import { hideNav } from '../../../actions/nav'
import { val } from '../../../utilities/functions'
import { SERVER_URL } from '../../../data/env'

const AddSport = () => {
    const dispatch = useDispatch()

    const [specialties, setSpecialties] = useState([{ fr: null, eng: null  }])
    const [categories, setCategories] = useState([''])
    const [reset, setReset] = useState(false)

    useEffect(() => {
        document.getElementById('root').scrollTo(0, 0)
        dispatch(hideNav('add-sport'))
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const data = {
            name_fr: val('name_fr'),
            name_eng: val('name_eng'),
            specialties: specialties.length > 0 ? specialties : 'dna',
            categories: categories.length > 0 ? categories : 'dna'
        }

        // add the sport
        try {
            await axios.post(`${SERVER_URL}/sports/add`, data)

            dispatch(setAlert({ status: 'open', message: 'Sport added with success' }))
            setReset(true)
        } catch (error) {
            dispatch(setAlert({ status: 'open', message: error?.response?.data?.message }))
        }
    }
    
    const addSpecialty = () => {
        setSpecialties(prev => [...prev, { fr: null, eng: null }])
        setTimeout(() => {
            document.getElementById(`fr${specialties.length}`).focus()
        }, 10)
    }

    const removeSpecialty = () => {
        const arr = Array.from(specialties)
        if (arr.length > 0) arr.pop()
        setSpecialties(arr)
    }

    const addCategory = () => {
        setCategories(prev => [...prev, 'lol'])
        setTimeout(() => {
            document.getElementById(`cat${categories.length}`).focus()
        }, 10)
    }

    const removeCategory = () => {
        const arr = Array.from(categories)
        if (arr.length > 0) arr.pop()
        setCategories(arr)
    }
    
    const handleSpecChange = (e, lang) => {
        const arr = Array.from(specialties)
        arr[e.target?.dataset?.ind] = { ...arr[e.target?.dataset?.ind], [lang]: e.target.value }
        setSpecialties(arr)
    }

    const handleCategoryChange = (e) => {
        const arr = Array.from(categories)
        arr[e.target?.dataset?.ind] = e.target?.value
        setCategories(arr)
    }
 
    return (
        <div id='add-sport'>
            <h1>Add a Sport</h1>

            <form onSubmit={handleSubmit}>
                <h2>Français</h2>
                <h2>English</h2>

                <Input id='name_fr' label='Nom' className='delay' reset={{ reset, setReset }}/>
                <Input id='name_eng' label='Name' className='delay' reset={{ reset, setReset }}/>

                {specialties.map((spec, i) => {
                    return (
                        <div className={`row ${i === 0 ? 'delay' : ''}`} key={i}>
                            <Input id={`fr${i}`} label={`Spécialité ${i + 1}`} data-ind={i} onChange={(e) => handleSpecChange(e, 'fr')} reset={{ reset, setReset }}/>
                            <Input id={`eng${i}`} label={`Specialty ${i + 1}`} data-ind={i} onChange={(e) => handleSpecChange(e, 'eng')} reset={{ reset, setReset }}/>
                        </div>    
                    )
                })}

                <div className="row buttons">
                    <button type='button' className='border-button full' onClick={addSpecialty}>Add Specialty +</button>
                    <button type='button' className='border-button full' onClick={removeSpecialty}>Remove Specialty -</button>
                </div>

                {categories.map((cat, i) => {
                    return (
                        <Input id={`cat${i}`} label={`Category ${i + 1}`} classNames={i === 0 ? 'delay' : 'no-delay'} data-ind={i} key={i} onChange={handleCategoryChange} reset={{ reset, setReset }}/>
                    )
                })}

                <div className="row buttons">
                    <button type='button' className='border-button full' onClick={addCategory}>Add Category +</button>
                    <button type='button' className='border-button full' onClick={removeCategory}>Remove Category -</button>
                </div>


                <StateButton initial='Add' success='Added Sucessfully' orange/>
            </form>
        </div>
    )
}

export default AddSport
