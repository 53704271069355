import React, { useState, useRef } from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// actions & components
import { setNav } from '../../../actions/nav'
import DelayLink from '../../tools/DelayLink/DelayLink'

// images
import LogoMedium from '../../../assets/images/logoMedium.png'
import UserIcon from '../../../assets/images/icons/userIcon.png'
import { setLang } from '../../../actions/lang'

const MainNav = () => {
    // states & variables
    const dispatch = useDispatch()
    const nav = useSelector((state) => state.nav)
    const auth = useSelector((state) => state.auth)
    const lang = useSelector((state) => state.lang)

    const [opened, setOpened] = useState(false)
    const [out, setOut] = useState(false)

    // functions
    const toggle_menu = () => {
        setOpened((prev) => !prev)
    }

    const toggleLang = () => {
        console.log('Language: ', lang?.name)
        dispatch(setLang(lang?.name === 'eng' ? 'fr' : 'eng'))
    }

    // change page 
    const changePage = (el) => {
        // trigger current page's animation out
        document.getElementById(nav?.currentPage).classList.add('out')
        // change currentPage state
        const nextpage = el?.target?.dataset?.page
        if (nextpage === 'login' && auth?.logged) setOut(true)
        dispatch(setNav(nextpage))
    }

    return (
        <>
        <div id='main-nav' className={out ? 'out' : ''}>
            <div className="dk dk-only">
                <Link to='/' className="left">
                    <img src={LogoMedium} alt="Athlete-X Logo" />
                </Link>
                <ul className="center menu-links">
                    <li><DelayLink to='/' data-page='home' onDelayStart={changePage} className={nav?.currentPage === 'home' ? 'active' : ''}>{lang?.pages?.[0]?.displayed}</DelayLink></li>
                    <li><DelayLink to='masks' data-page='masks' onDelayStart={changePage} className={nav?.currentPage === 'masks' ? 'active' : ''}>{lang?.pages?.[1]?.displayed}</DelayLink></li>
                    {/* <li><Link to='https://athlete-xstore.myshopify.com/' target='_blank' data-page='store'>{lang?.pages?.[2]?.displayed}</Link></li> */}
                    <li><DelayLink to='contact-us' data-page='contact-us' onDelayStart={changePage} className={nav?.currentPage === 'contact-us' ? 'active' : ''}>{lang?.pages?.[3]?.displayed}</DelayLink></li>
                    <li><DelayLink to='auth' data-page={'login'} onDelayStart={changePage} className={nav?.currentPage === 'auth' ? 'active' : ''}>{auth?.logged ? lang?.pages?.[4]?.displayed : lang?.pages?.[6]?.displayed}</DelayLink></li>
                </ul>
                <div className="right">
                    <button id='lang-button' onClick={toggleLang}>{lang?.name === 'eng' ? 'fr' : 'eng'}</button>
                </div>
            </div>

            <div className={`mob mob-only ${opened ? 'opened' : ''}`}>
                <div className="bar">
                    <div className="hamburger" onClick={toggle_menu}>
                        <div className="hamburger-line top-line"></div>
                        <div className="hamburger-line bot-line"></div>
                        <div className="hamburger-line x-line-1"></div>
                        <div className="hamburger-line x-line-2"></div>
                    </div>
                    <Link to='/' className='logo'><img src={LogoMedium} alt="Athlete-X Logo" /></Link>
                    <Link to='/auth' className='user' onClick={opened ? toggle_menu : null}><img src={UserIcon} alt="Profile" /></Link>
                </div>
                {opened &&
                <div className="dropdown">
                    <ul className='menu-links'>
                        <li><Link to='/' onClick={toggle_menu}>{lang?.pages?.[0]?.displayed}</Link></li>
                        <li><Link to='masks' onClick={toggle_menu}>{lang?.pages?.[1]?.displayed}</Link></li>
                        {/* <li><Link to='https://athlete-xstore.myshopify.com/' target='_blank' onClick={toggle_menu}>{lang?.pages?.[2]?.displayed}</Link></li> */}
                        <li><Link to='contact-us' onClick={toggle_menu}>{lang?.pages?.[3]?.displayed}</Link></li>
                        <li><button id='mob-lang-button' onClick={toggleLang}>{lang?.name === 'eng' ? 'fr' : 'eng'}</button></li>
                    </ul>
                </div>}
            </div>
        </div>
        {opened && 
        <div className='black-overlay-25'></div>}
        </>
    )
}

export default MainNav