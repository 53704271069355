import * as actions from '../data/actions_types'

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
const token = localStorage.getItem('token') ? localStorage.getItem('token') : null

// eslint-disable-next-line import/no-anonymous-default-export
export default (auth = { logged: user ? true : false, user, token }, action) => {
    switch (action.type) {
        case actions.LOGIN:
            localStorage.setItem('logged', 'true')
            localStorage.setItem('user', JSON.stringify(action?.payload?.user))
            localStorage.setItem('token', action?.payload?.token)
            return { logged: true, user: action?.payload?.user, token: action?.payload?.token }
        case actions.LOGOUT:
            localStorage.removeItem('logged')
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            return { logged: false, user: null, token: null }
        case actions.AUTH_VERIFY: 
            localStorage.setItem('user', JSON.stringify(action?.payload?.user))
            return { logged: auth.logged, user: action?.payload?.user, token: auth.token }
        case actions.VERIFY_TOKEN_VALIDITY: 
            if (!action?.payload?.valid) {
                return { ...auth, logged: false }
            }
            return { ...auth, logged: true }
        default:
            return auth
    }
}