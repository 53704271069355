import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'

import { setNav } from '../../../actions/nav'

import heroImage from '../../../assets/images/homePlayers.jpg'
import arrows from '../../../assets/images/arrows.png'
import { DelayLink } from '../../tools/DelayLink/DelayLink'

const Home = () => {

    // hooks
    const home = useRef(null)
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.lang)

    const changePage = (e) => {
        // trigger current page's animation out
        home.current.classList.add('out')
        // change currentPage state
        dispatch(setNav(e?.target?.dataset?.page))
    }

    // on mount
    useEffect(() => {
        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)
        // animation in
        setTimeout(() => {
            home.current.style.display = 'block'
        }, 500)
        // setting currentPage to home and navbar to nav1
        dispatch(setNav('home'))
    }, [dispatch])

    return (
        <div id='home' ref={home}>
            <main>
                <div className='left'>
                    <h1 alt='Empowering Athletes & Coaches'>
                        <span id='big-h1'>{lang?.h?.home_h1_1}</span>
                        <span id='small-h1'>{lang?.h?.home_h1_2}</span>
                    </h1>
                    <p className='befored-p'>{lang?.p?.home_explainer}</p>
                </div>
                <div className="right">
                    <img src={heroImage} alt="lacrosse athletes" />
                    <span className='little-text'>Learn. Adapt. Grow.</span>
                </div>
                <DelayLink to='take-the-test' data-page='test' onDelayStart={changePage} className='border-button mob-full orange'>{lang?.buttons?.take_test}</DelayLink>
            </main>

            <p className='copyrights'>{lang?.p?.copyrights}</p>

            <div className="line v-line" id='home-line-1'></div>
            <div className="line v-line" id='home-line-2'></div>
            <div className="line v-line" id='home-line-3'></div>
            <div className='arrows-cont dk-only'><img src={arrows} className='arrows' alt="background arrows" /></div>
            <svg className='hex-svg dk-only' width="1061" height="1225" viewBox="0 0 1061 1225" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='hex-path' d="M530.5 0L1060.94 306.25V918.75L530.5 1225L0.0594482 918.75V306.25L530.5 0Z" fill="transparent" stroke='hsl(var(--bg-accent))' strokeWidth={3}/>
            </svg>
        </div>
    )
}

export default Home
