import React, { useState } from 'react'
import './styles.css'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { dna } from '../../../../utilities/formats'

const Sports = ({ sports }) => {
    const navigate = useNavigate()

    const lang = useSelector((state) => state.lang)
    const eng = useSelector((state) => state.lang?.name) === 'eng' ? true : false
    
    const [i, setI] = useState(0)
    
    const next = () => {   
        if (i + 1 < sports?.length) {
            setI(prev => ++prev)
        } else {
            setI(0)
        }
    }

    const prev = () => {
        if (i === 0) {
            setI(sports?.length - 1)
        } else {
            setI(prev => --prev)
        }
    }

    return (
        <div className='sports'>
            <div className='sports-header'>
                <h2>Sports</h2>
                {sports?.length > 0 && sports?.length < 2 && <Link to='settings#sports'>{lang?.buttons?.add}</Link>}
            </div>

            {sports?.length > 0 
            ?
            <div>
                <ul onClick={() => navigate('settings#sports')}>
                    <li className='hl'>
                        <p>{lang?.kw?.sport}</p>
                        <p>{eng ? dna(sports?.[i]?.sport?.name_eng) : dna(sports?.[i]?.sport?.name_fr)}</p>
                    </li>
                    <li className=''>
                        <p>{lang?.kw?.position}</p>
                        <p>{eng ? dna(sports?.[i]?.specialty_eng) : dna(sports?.[i]?.specialty_fr)}</p>
                    </li>
                    <li className='hl'>
                        <p>{lang?.kw?.category}</p>
                        <p>{dna(sports?.[i]?.category)}</p>
                    </li>


                    <div className="line v-line" id='sports-line'></div>
                </ul>

                <div className="controls">
                    <button className="left" onClick={prev}>{'<'}</button>
                    <p className='title'>{eng ? sports?.[i]?.sport?.name_eng : sports?.[i]?.sport?.name_fr}</p>
                    <button className="right" onClick={next}>{'>'}</button>
                </div>
            </div>
            :
            <Link to='settings#sports' className="edit-overlay">
                {lang?.buttons?.add_sport}
            </Link>
            }
        </div>
    )
}


export default Sports
