import React, { useEffect, useState, useRef } from 'react'
import './styles.css'
import { useSelector } from 'react-redux'

const RangeInput = ({ i, mask, onSelection, reset }) => {
    const lang = useSelector((state) => state.lang)
    
    const ref = useRef()

    const [ind, setInd] = useState(false)

    useEffect(() => {
        if (ind !== false) ref.current.style.setProperty('--thumb-offset', ind)
    }, [ind])

    const handleSelection = (ind, value, mask) => {
        setInd(ind)

        if (onSelection) onSelection(value, mask)
    }

    useEffect(() => {
        if (reset.state === true) {
            setInd(false)
            reset.set(false)
        }
    }, [reset])

    return (
        <div className="range-input-wrap">
            <div className="helpers">
                <span>{lang?.kw?.least_like_me}</span>
                <span>{lang?.kw?.most_like_me}</span>  
            </div>


            <div className='range-input' ref={ref}>
                <div className={`thumb ${ind !== false ? 'active' : ''}`}></div>

                <button className={ind === 0 ? 'selected' : 0} onClick={() => handleSelection(0, 0, mask)}>1</button>
                <button className={ind === 1 ? 'selected' : 0} onClick={() => handleSelection(1, 1, mask)}>2</button>
                <button className={ind === 2 ? 'selected' : 0} onClick={() => handleSelection(2, 3, mask)}>3</button>
                <button className={ind === 3 ? 'selected' : 0} onClick={() => handleSelection(3, 5, mask)}>4</button>
                <button className={ind === 4 ? 'selected' : 0} onClick={() => handleSelection(4, 9, mask)}>5</button>
                <button className={ind === 5 ? 'selected' : 0} onClick={() => handleSelection(5, 13, mask)}>6</button>
                <button className={ind === 6 ? 'selected' : 0} onClick={() => handleSelection(6, 18, mask)}>7</button>
            </div>
        </div>
    )
}

export default RangeInput
