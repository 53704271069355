import React, { useEffect, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'

import MaskIcon from '../../../tools/MaskIcon/MaskIcon'
import DelayLink from '../../../tools/DelayLink/DelayLink'
import colorTestFr from '../../../../assets/images/colorTestFr.png'
import colorTestEng from '../../../../assets/images/colorTestEng.png'

import { setAlert } from '../../../../actions/alert'
import CenterModal from '../../../modals/CenterModal/CenterModal'

const Masks = () => {
    const dispatch = useDispatch()

    const lang = useSelector((state) => state.lang)
    const p = useSelector((state) => state.profile)

    const [activeMask, setActiveMask] = useState('none')
    const [gritHov, setGritHov] = useState(false)
    const [colHov, setColHov] = useState(false)
    const [colorHelper, setColorHelper] = useState(false)
    const [maskHelper, setMaskHelper] = useState(false)
    const [gritHelper, setGritHelper] = useState(false)

    const selectMask = (i) => {
        setActiveMask(lang?.masks?.lower_names[i])
        setTimeout(() => document.getElementById('indicator').style.setProperty('--mask-index', i + 1), 75)
    }
    
    useEffect(() => {
        if (p && p?.tests?.status === 'completed') {
            setTimeout(() => {
                setActiveMask(p?.tests?.masks?.dom)
                const i = lang?.masks?.lower_names.indexOf(p?.tests?.masks?.dom)
                document.getElementById('indicator') && document.getElementById('indicator').style.setProperty('--mask-index', i + 1)
            }, 10)
        }
    }, [p])

    return (
        <>
        {p?.tests?.status !== 'completed'
        ? 
            <div className="no-masks">
                <p>{lang?.p?.take_the_test}</p>
                <DelayLink to='/take-the-test' className='border-button full orange'>{lang?.buttons?.take_test}</DelayLink>
            </div>
        :
            <>
            <div className='masks'>
                <div className="left">
                    <div className="test-masks">
                        <div className="row-between">
                            <h3>{lang?.kw?.masks}</h3>
                            <button className='helper' onClick={() => setMaskHelper(true)}>?</button>
                        </div>

                        <ul className="mask-grid padded-h-scrollbar">
                            <li className={`card-02 ${activeMask === p?.tests?.masks?.dom ? 'active' : ''}`} onClick={() => setActiveMask(p?.tests?.masks?.dom)}>
                                <MaskIcon name={p?.tests?.masks?.dom}/>
                                
                                <span className='big-number'>{p?.tests?.masks?.[p?.tests?.masks?.dom]}</span>
                                <div>
                                    <p>{lang?.masks?.texts?.[p?.tests?.masks?.dom]?.top3?.[0]}</p>
                                    <p>{lang?.masks?.texts?.[p?.tests?.masks?.dom]?.top3?.[1]}</p>
                                    <p>{lang?.masks?.texts?.[p?.tests?.masks?.dom]?.top3?.[2]}</p>
                                </div>
                                
                                <h4>{p?.tests?.masks?.dom}</h4>
                            </li>

                            {lang?.masks?.lower_names.map((mask, i) => {
                                return mask !== p?.tests?.masks?.dom ? (
                                    <li className={`card-01 ${activeMask === mask ? 'active' : ''}`} onClick={() => setActiveMask(mask)} key={i}>
                                        <MaskIcon name={mask}/>
                                        <span className='medium-number'>{p?.tests?.masks?.[mask]}</span>
                                        <h4>{mask}</h4>
                                    </li>
                                ) : <div key={i} style={{ display: 'none' }}></div>
                            })}
                        </ul>
                    </div>

                    <div className="colors">
                        <div className="row-between">
                            <h3>{lang?.kw?.colors}</h3>
                            <button className='helper' onClick={() => setColorHelper(true)}>?</button>
                        </div>

                        <div className={`card-01 ${colHov ? 'hovered' : ''}`} 
                            onMouseEnter={window.matchMedia('(hover: hover)').matches ? () => setColHov(true) : () => {}}
                            onMouseLeave={() => setColHov(false)}
                            onMouseDown={() => setColHov(prev => prev === false ? true : false)}
                        >
                            <div className="square" 
                                style={{ 
                                    '--pred': `${p?.tests?.colors?.red}fr`,
                                    '--pyellow': `${p?.tests?.colors?.yellow}fr`,
                                    '--pgreen': `${p?.tests?.colors?.green}fr`,
                                    '--pblue': `${p?.tests?.colors?.blue}fr`
                                }}>
                                <div className="portion bg-red"></div>
                                <div className="portion bg-yellow"></div>
                                <div className="portion bg-green"></div>
                                <div className="portion bg-blue"></div>
                            </div>
                            <div className="values">
                                <ul>
                                    <li>{lang?.kw?.red}</li>
                                    <li>{lang?.kw?.yellow}</li>
                                    <li>{lang?.kw?.green}</li>
                                    <li>{lang?.kw?.blue}</li>
                                </ul>
                                <ul>
                                    <li>{p?.tests?.colors?.red}</li>
                                    <li>{p?.tests?.colors?.yellow}</li>
                                    <li>{p?.tests?.colors?.green}</li>
                                    <li>{p?.tests?.colors?.blue}</li>
                                </ul>
                            </div>

                            <div className="pu-card">
                                <div className="inner">
                                    <p>{lang?.color_test?.results?.[p?.tests?.colors?.prim]}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grit">
                        <div className="row-between">
                            <h3>{lang?.kw?.grit}</h3>
                            <button className='helper' onClick={() => setGritHelper(true)}>?</button>
                        </div>

                        <div className={`card-01 ${gritHov ? 'hovered' : ''}`} 
                            onMouseEnter={window.matchMedia('(hover: hover)').matches ? () => setGritHov(true) : () => {}}
                            onMouseLeave={() => setGritHov(false)}
                            onMouseDown={() => setGritHov(prev => prev === false ? true : false)}
                        >
                            <span className="big-number">{p?.tests?.grit}</span>
                            <p className='padded-scrollbar ultra-thin'>{lang?.grit_test?.short_results?.[Math.floor(p?.tests?.grit) - 1]}</p>

                            <div className="pu-card">
                                <div className="inner">
                                    <p>{lang?.grit_test?.results?.[Math.floor(p?.tests?.grit) - 1]}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="line v-line dk-only" id='mask-separator'></div>
                </div>

                <div className="center mob-only">
                    {[0, 1, 2, 3, 4, 5, 6].map((i) => {
                        return (
                            <button className={`mask ${activeMask === lang?.masks?.lower_names[i] ? 'active' : ''}`} onClick={() => selectMask(i)} key={i}>
                                <MaskIcon name={lang?.masks?.lower_names[i]}/>
                                <p>{lang?.masks?.upper_names[i]}</p>
                            </button>
                        )
                    })}

                    {activeMask !== 'none' &&
                        <svg id='indicator' className={activeMask !== 'none' ? 'active' : null} viewBox="0 0 38 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 5.1057L33.9232 29.5L2.5 53.8943L2.5 5.1057Z" fill="#111111" stroke="hsl(var(--bg-accent))" strokeWidth="5"/>
                        </svg>
                    }
                </div>
                
                {activeMask === 'none' 
                ?
                    <div className="no-mask">
                        <p>{lang?.p?.select_a_mask}</p>
                    </div>
                :
                    <>
                    <div className="right">
                        <div className="masks-right-header">
                            <h2>{activeMask}</h2>
                            <MaskIcon name={activeMask}/>
                        </div>
                        <div className="body" id='masks-texts-body'>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections[0]}</h3>
                                <p>{lang?.masks?.texts?.[activeMask]?.overview}</p>
                            </div>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections?.[1]}</h3>
                                <ul>
                                    {lang?.masks?.texts?.[activeMask]?.chars.map((char, index) => {
                                        return <li key={index}>{char}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections?.[2]}</h3>
                                <ul>
                                    {lang?.masks?.texts?.[activeMask]?.qualities.map((char, index) => {
                                        return <li key={index}>{char}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections?.[3]}</h3>
                                <ul>
                                    {lang?.masks?.texts?.[activeMask]?.drawbacks.map((char, index) => {
                                    return <li key={index}>{char}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections?.[4]}</h3>
                                <ul>
                                    {lang?.masks?.texts?.[activeMask]?.motivate.map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{lang?.masks?.texts?.sections?.[5]}</h3>
                                <ul>{lang?.masks?.texts?.[activeMask]?.interact.map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{`${lang?.masks?.texts?.sections?.[6]} ${activeMask.toUpperCase()} ${lang?.masks?.texts?.sections?.[7]}`}</h3>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.light[0].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                                <h3 className='mt-05'>{lang?.masks?.texts?.sections?.[9]}</h3>
                                <h4 className='ml-1'>{lang?.masks?.texts?.sections?.[10]}</h4>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.light[1].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                                <h4 className='ml-1'>{lang?.masks?.texts?.sections?.[11]}</h4>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.light[2].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="body-block">
                                <h3>{`${lang?.masks?.texts?.sections?.[6]} ${activeMask.toUpperCase()} ${lang?.masks?.texts?.sections?.[8]}`}</h3>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.dark[0].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                                <h3 className='mt-05'>{lang?.masks?.texts?.sections?.[9]}</h3>
                                <h4 className='ml-1'>{lang?.masks?.texts?.sections?.[10]}</h4>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.dark[1].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                                <h4 className='ml-1'>{lang?.masks?.texts?.sections?.[11]}</h4>
                                <ul className='ml-1'>{lang?.masks?.texts?.[activeMask]?.dark[2].map((e, index) => {
                                    return <li key={index}>{e}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    </>
                }
            </div>
        </>}
        {colorHelper &&
        <CenterModal maxSize closeModal={() => setColorHelper(false)}>
            <img style={{ objectFit: 'contain', width: '100%', height: '100%' }} src={lang?.name === 'fr' ? colorTestFr : colorTestEng} alt="color test explanations" />
        </CenterModal>}
        {maskHelper &&
        <CenterModal closeModal={() => setMaskHelper(false)}>
            <h2>{lang?.mask_test?.helper?.[0]}</h2>
            <p>{lang?.mask_test?.helper?.[1]}</p>
            <p>{lang?.mask_test?.helper?.[2]}</p>
        </CenterModal>}
        {gritHelper &&
        <CenterModal closeModal={() => setGritHelper(false)}>
            <h2>{lang?.grit_test?.name}</h2>
            <p>{lang?.grit_test?.intro}</p>
        </CenterModal>}
        </>
        
    )
}

export default Masks
