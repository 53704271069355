import React, { useEffect, useRef } from 'react'
import './styles.css'

import { motion } from 'framer-motion'

import MaskIcon from '../../../../tools/MaskIcon/MaskIcon'

const Banner = ({ member, ind }) => {
    const h3 = useRef()

    const color = member?.id?.acc_type === 'coach' ? "#fff" : "hsl(var(--bg-accent))"

    useEffect(() => {
        h3.current.style.setProperty('--color', color)
    }, [color])

    return (
        <motion.li layout transition={{ duration: 0.5, type: 'spring' }} className='banner' style={{ '--delay': `${ind * 150 + 300}ms` }}>
            <p className='axid'>#{member?.id?.axid}</p>
            <h3 ref={h3}>{member?.id?.firstname}<br />{member?.id?.lastname}</h3>

            <MaskIcon name={member?.tests?.masks?.dom} color={color}/>

            <MaskIcon name={member?.tests?.masks?.sec} color={color}/>

            <p>{member?.id?.acc_type}</p>

            <svg className='banner-svg' viewBox="0 0 192 358" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H1.5V263.586L41.238 353.25H85.75V354.75H40.262L0 263.904V0ZM190 263.586V0H191.5V263.904L151.238 354.75H105.75V353.25H150.262L190 263.586Z" fill={color}/>
                <rect x="83" y="350" width="24" height="8" fill={color}/>
            </svg>
        </motion.li>
    )
}

export default Banner
