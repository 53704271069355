import React from 'react'

import './styles.css'

export const Lined = ({ children, mobonly }) => {
    
    let mob = mobonly ? false : true

    return (
        <div className={`lined ${mob ? '' : 'mob'}`}>
            {children}
            <div className="input-topline input-line"></div>
            <div className="input-botline input-line"></div>
        </div>
    )
}

export default Lined