import { SET_ALERT, SET_ALERT_MESSAGE, SET_ALERT_STATUS } from '../data/actions_types'

export const setAlert = (alert) => (dispatch) => {
    dispatch({ type: SET_ALERT, payload: alert})
}

export const setAlertStatus = (status) => (dispatch) => {
    dispatch({ type: SET_ALERT_STATUS, payload: status})
}

export const setAlertMessage = (message) => (dispatch) => {
    dispatch({ type: SET_ALERT_MESSAGE, payload: message})
}