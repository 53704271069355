import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './styles.css'

import Input from '../../tools/Input/Input'
import DDInput from '../../tools/DDInput/DDInput'
import InputRow from '../../tools/InputRow/InputRow'
import Lined from '../../tools/Lined/Lined'
import FormButton from '../../tools/FormButton/FormButton'

import { setNav } from '../../../actions/nav'
import { setAlert } from '../../../actions/alert'
import { createAccount, login } from '../../../actions/auth'
import CenterModal from '../../modals/CenterModal/CenterModal'

const Auth = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authRef = useRef(null)
    const authForm = useRef(null)
    const lang = useSelector((state) => state.lang)
    const auth = useSelector((state) => state.auth)
    const [isLogin, setIsLogin] = useState(window.location.hash === '#create-account' ? false : true)
    const [process, setProcess] = useState('initial')
    const [orgHelper, setOrgHelper] = useState(false)

    const setLinesLeft = () => {
      if (authForm.current) {
        const rect = authForm.current.getBoundingClientRect()
        const lines = document.querySelectorAll('.input-line')
        lines.forEach(e => {
            e.style.setProperty('--left', rect.left)
        });
      }
    }

    // to delete
    useEffect(() => {
      console.log(process)
    }, [process])

    // redirect based on login status
    useEffect(() => {
      if (auth && auth?.logged) {
        navigate('/profile', { replace: true })
      }
    }, [])

    // update url based on isLogin
    useEffect(() => {
      if (auth && !auth.logged) {
        if (isLogin) 
          navigate('#login', { replace: true })
        else
          navigate('#create-account', { replace: true })
      }
    }, [isLogin])

    useEffect(() => {
        // set lines css variables
        setLinesLeft()

        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)

        // animation in
        setTimeout(() => {
            authRef.current.style.display = 'block'
        }, 500)
        // setting currentPage to home and navbar to nav1
        dispatch(setNav('auth'))
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setLinesLeft()
        })

        return () => {
            document.removeEventListener('resize', setLinesLeft)
        }
    }, [])

    useEffect(() => {
        if (document.querySelectorAll('.input-line').length > 0)
            setLinesLeft()
    }, [isLogin])

    // form submission
    const getValue = (id) => {
        return document.getElementById(id)?.value
    }

    const handleCreate = (e) => {
        e.preventDefault()

        if (process !== 'initial') return

        // collect data from form 
        const data = { firstname: getValue('firstname'), 
        lastname: getValue('lastname'), 
        email: getValue('email'), 
        password: getValue('password'), 
        confirm_password: getValue('confirmPassword'), 
        acc_type: document.getElementById('acc_type')?.dataset?.value }
        console.log(data)
        
        // validate data
        if (!validateCreateAccValues(data)) 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4] })) 

        setProcess('loading')

        // trigger createAccount action
        dispatch(createAccount(data, setAlert, navigate, lang, setProcess))
    }

    const handleLogin = (e) => {
        e.preventDefault()
        setProcess('loading')

        // collect data from form
        const data = { email: getValue('email'), password: getValue('password') }
        
        // trigger login action
        dispatch(login(data, setAlert, navigate, lang, setProcess))
    }

    const validateCreateAccValues = (values) => {
      let valid = true
      for (const key in values) {
        if (!(values[key])) {
          valid = false
          return console.log('Empty value: ', key)
        }
      }
      return valid
    } 

    return (
      <>
      <div id="auth" ref={authRef}>
        <main>
          <h1>{isLogin ? lang?.h?.auth_login : lang?.h?.auth_create}</h1>

          <form ref={authForm} onSubmit={isLogin ? handleLogin : handleCreate}>
            {!isLogin && (
              <InputRow>
                <Lined>
                  <Input id="firstname" label={lang?.inputs?.firstname} />
                </Lined>
                <Lined mobonly>
                  <Input id="lastname" label={lang?.inputs?.lastname} /> 
                </Lined>
              </InputRow>
            )}

            <InputRow>
              <Lined>
                <Input id="email" label={lang?.inputs?.email} defaultValue={auth?.user ? auth?.user?.email : ''}/>
              </Lined>
            </InputRow>

            <InputRow>
              <Lined>
                <Input
                  id="password"
                  label={lang?.inputs?.password}
                  type="password"
                  down={isLogin ? 'false' : "true"}
                  autoFocus={auth?.user ? true : false}
                />
              </Lined>
            </InputRow>

            {!isLogin && (
              <>
                <InputRow>
                  <Lined>
                    <Input
                      id="confirmPassword"
                      label={lang?.inputs?.confirm_password}
                      type="password"
                    />
                  </Lined>
                </InputRow>

                <InputRow>
                  <Lined>
                    <DDInput
                      id="acc_type"
                      label={lang?.inputs?.acc_type}
                      options={lang?.inputs?.acc_type_options}
                      required
                    />
                  </Lined>
                </InputRow>
              </>
            )}

            {/* form button */}
            <InputRow>
              <Lined top="0">
                <FormButton
                  state={process}
                  text={
                    isLogin
                      ? lang?.form_buttons?.login
                      : lang?.form_buttons?.create_acc
                  }
                />
              </Lined>
            </InputRow>
          </form>

          <div className="links">
            {/* new here / already in */}
            <p>
              {isLogin ? lang?.p?.new_here[0] : lang?.p?.already_in[0]}
              <button onClick={() => setIsLogin((prev) => !prev)}>
                {isLogin ? lang?.p?.new_here[1] : lang?.p?.already_in[1]}
              </button>
            </p>

            {/* create org account helper */}
            {!isLogin ? 
            <p>
              {lang?.p?.create_org_link?.[0]}
              <button onClick={() => setOrgHelper(true)}>{lang?.p?.create_org_link?.[1]}</button>  
            </p>
            :
            <p>
              {lang?.kw?.forgot_password?.[0]}
              <button><Link to='/forgot-password'>{lang?.kw?.forgot_password?.[1]}</Link></button>
            </p>
            }
          </div>

          <div className="line v-line" id="auth-line-1"></div>
          <div className="line v-line" id="auth-line-2"></div>
        </main>

        <p className="copyrights">{lang?.p?.copyrights}</p>
      </div>
      {orgHelper &&
      <CenterModal closeModal={() => setOrgHelper(false)}>
        <h2>{lang?.p?.create_org_helper?.[0]}</h2>
        <p>{lang?.p?.create_org_helper?.[1]}</p>
        <p>{lang?.p?.create_org_helper?.[2]}</p>
      </CenterModal>
      }
      </>
    );
}

export default Auth
