import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// functionnal component to import 
export const DelayLink = (props) => {

    // props
    const { delay, onDelayStart, onDelayEnd, replace, to, ...rest } = props
    let timeout = null

    // cancel if any existing timeout
    useEffect(() => {
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }, [timeout]);

    // router dom functions
    let navigate = useNavigate()
    let location = useLocation()

    // on click
    const handleClick = (e) => {

        e.preventDefault()

        // check if link to same url
        if (location?.pathname === to) return

        // calling onDelayStart's function
        onDelayStart(e, to)

        // calling onDelayEnd's function
        timeout = setTimeout(() => {
            if (replace) {
              navigate(to);
            } else {
              navigate(to);
            }
            onDelayEnd(e, to);
          }, delay);


    }

    return (
        <Link {...rest} to={to} onClick={handleClick} />
    )
}

DelayLink.propTypes = {

    // Milliseconds to wait before registering the click.
    delay: PropTypes.number,

    // Called after the link is clicked and before the delay timer starts.
    onDelayStart: PropTypes.func,

    // Called after the delay timer ends.
    onDelayEnd: PropTypes.func,

    // Replace history or not
    replace: PropTypes.bool,

    // Link to go to
    to: PropTypes.string
    
};

DelayLink.defaultProps = {
    replace: false,
    delay: 1500,
    onDelayStart: () => {},
    onDelayEnd: () => {}
};
  

export default DelayLink