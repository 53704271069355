import React, { useState, useEffect, useRef } from 'react'
import './styles.css'

const TextArea = ({ id, label, max, defaultValue, reset }) => {
    const ref = useRef(null)

    const [done, setDone] = useState(false)
    const [char, setChar] = useState(0)

    const handleFocusLoss = (e) => {
        if (e.target.value === '' || e.target.value === null) setDone(false)
        else setDone(true)
    }

    const handleInput = (e) => {
        setChar(e.target.value.length)
    }
    
    useEffect(() => {
        if (ref.current.value.trim().length > 0) setDone(true)
    }, [defaultValue])

    // reset to default value
    useEffect(() => {
        if (reset) {
            if (reset.reset === true) {
                if (defaultValue) {
                ref.current.value = defaultValue
                } else {
                ref.current.value = null
                console.log('nulllll')
                setDone(false)
                }

                reset.setReset(false)
            }
        }
    }, [reset])

    return (
        <div className={`text-area ${done ? 'done' : ''}`}>
            <label htmlFor={id}>{label}</label>
            <textarea ref={ref} defaultValue={defaultValue} name={id} id={id} maxLength={1000} required onBlur={handleFocusLoss} onFocus={() => setDone(true)} onInput={handleInput}></textarea>
            <span>{char} / {max}</span>
        </div>
    )
}

TextArea.defaultProps = {
    max: 50
}

export default TextArea