export const CLIENT_URL = 'https://athlete-x.io'
export const SERVER_URL = 'https://athlete-x.herokuapp.com'

// development 
// http://localhost:3000
// http://localhost:5000

// deployed
// https://athlete-x.io
// https://axmvp.netlify.app
// https://athlete-x.herokuapp.com