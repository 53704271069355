import * as API from '../api/index'
import * as actions from '../data/actions_types'

 
// create user
export const createAccount = (_data, setAlert, navigate, lang, setProcess) => async (dispatch) => {
    try {
        console.log(_data)
        const { data } = await API.createAccount(_data)

        // trigger login page's animation out
        setProcess('success')
        document.getElementById('auth').classList.add('out')
        setTimeout(() => {
            dispatch({ type: actions.LOGIN, payload: data })
            navigate(`/verify`)
        }, 1500)
    } catch (error) {
        setProcess('initial')
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

// login user
export const login = (_data, setAlert, navigate, lang, setProcess) => async (dispatch) => {    
    try {
        const { data } = await API.login(_data)

        // trigger login page's animation out
        setProcess('success')
        document.getElementById('auth').classList.add('out')
        setTimeout(() => {
            navigate('/profile')
            dispatch({ type: actions.LOGIN, payload: data })
        }, 1500)
    } catch (error) {
        setProcess('initial')
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

// logout 
export const logout = (navigate) => (dispatch) => {
    navigate('/')

    window.location.reload()

    dispatch({ type: actions.LOGOUT, payload: null})
}

// verify account
export const verify = (_data, setAlert, navigate, lang, setProcess) => async (dispatch) => {
    try {
        const { data } = await API.authVerify(_data)

        // trigger login page's animation out
        setProcess('success')
        document.getElementById('auth-verify').classList.add('out')
        setTimeout(() => {
            navigate('/profile')
            dispatch({ type: actions.AUTH_VERIFY, payload: data })
        }, 1500)
    } catch (error) {
        setProcess('failure')
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

// verify token validity
export const verifyTokenValidity = (token, onSessExp) => async (dispatch) => {
    try {
        const { data } = await API.verifyTokenValidity(token)
        if (!data?.valid) onSessExp()
        dispatch({ type: actions.VERIFY_TOKEN_VALIDITY, payload: data })
    } catch (error) {
        console.log('ERROR => ', error)
    }
}