import React, { useEffect, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Intro from "./Intro"
import PartOne from "./PartOne"
import PartTwo from "./PartTwo"
import PartThree from "./PartThree"
import Results from "./Results"
import InputRow from '../../tools/InputRow/InputRow'
import logout_icon from '../../../assets/images/icons/logout.png'

import { setNav } from '../../../actions/nav'
import { setTests } from '../../../actions/profile'
import { getMyProfile } from '../../../actions/profile'

const initial_masks = { captain: 0, soldier: 0, maven: 0, engineer: 0, strategist: 0, player: 0, driven: 0 }
const initial_color = { blue: 0, green: 0, red: 0, yellow: 0 }
const initial_grit = 0

const Test = () => {
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.lang)
    const auth = useSelector((state) => state.auth)
    const profile = useSelector((state) => state.profile)

    const [step, setStep] = useState(0)
    const [bars, setBars] = useState({ 1: 41, 2: 41, 3: 41 })
    const [results, setResults] = useState({ masks: initial_masks, colors: initial_color, grit: initial_grit })

    // on step change
    useEffect(() => {
        if (step % 1 !== 0) setTimeout(() => setStep(prev => prev + 0.5), 750)
        else {
            // update progress bar css vars
            if (step > 1 && step < 9) setBars({ ...bars, 1: 41 - (41 / 7 * (step - 1)) })
            else if (step > 9 && step < 41) setBars({ ...bars, 1: 0, 2: 41 - (41 / 31 * (step - 8)) })
            else if (step > 41 && step < 53 ) setBars({ 1: 0, 2: 0, 3: 41 - (41 / 10 * (step - 42)) })
            // update local storage
            if (step !== 0) sessionStorage.setItem('test-step', step)
        }
    }, [step])


    // on test completion
    const submitTest = () => {
        dispatch(setTests(results, lang))
    }

    // on mount
    useEffect(() => {
        // reset scroll to 0 and display to block
        document.getElementById('root').scrollTo(0, 0)
        setTimeout(() => document.getElementById('test').style.display = 'unset', 300)
        // setting currentPage to home and navbar to nav1
        dispatch(setNav('test'))

        // set step and result states based on local storage
        const last = { step: Number(JSON.parse(sessionStorage.getItem('test-step'))), results: JSON.parse(sessionStorage.getItem('test-results')) }
        setStep(last?.step)
        setResults(prev => last?.results ? last.results : prev)
    }, [dispatch])

    // store results to localstorage on change
    useEffect(() => {
        if (results?.masks?.captain) sessionStorage.setItem('test-results', JSON.stringify(results))
    }, [results])

    useEffect(() => {
        if (auth.logged) {
            dispatch(getMyProfile(lang))
        }
    }, [auth])

    return (
        <div id='test'>
            {!auth?.logged ?
            <div className="part-page">
                <h1><img src={logout_icon} alt="not logged" /></h1>
                <p>{lang?.p?.login_to_test}</p>
                <InputRow style={{ maxWidth: 'min(550px, 85%)'  }} >
                    <Link to='/auth#login' className='border-button full' >{lang?.kw?.log_in}</Link>
                    <Link to='/auth#create-account' className='border-button full orange' >{lang?.kw?.create_account}</Link> 
                </InputRow>
            </div>
            :
            profile?.tests?.status && profile?.tests?.status === 'completed' && step < 52.5
            ?
            <div className="part-page">
                <h1>
                    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.5 44.1777L18.7783 44.4561L55.2422 7.99219L47.4639 0.213867L18.728 28.9497L8.55029 18.772L0.771973 26.5503L18.4497 44.228L18.5 44.1777Z" fill="hsl(var(--bg-accent))"/>
                    </svg>
                </h1>
                <p>{lang?.p?.already_took_test}</p>
                <Link className='border-button orange' to='/profile#masks' >{lang?.kw?.go_to_profile}</Link>
            </div>
            :
            <>
                {step >= 1 && step < 9
                ? 
                    <PartOne step={step} setStep={setStep} setResults={setResults} />
                : step >= 9 && step < 42 
                ?
                    <PartTwo step={step} setStep={setStep} setResults={setResults} />
                : step >= 42 && step < 53 
                ?
                    <PartThree step={step} setStep={setStep} setResults={setResults} submitTest={submitTest} />
                : step >= 53 ?
                    <Results results={results} />
                :
                    <Intro step={step} setStep={setStep} />
                }

                {step >= 2 && (step !== 9 && step !== 9.5) && (step !== 42 && step !== 42.5) && step !== 53 &&
                <div className="progress-bar">
                    <div className='square orange'>1</div>
                    <div style={{ '--right': bars?.[1] }} className='bar'></div>
                    <div className={`square ${step > 8 ? 'orange' : ''}`}>2</div>
                    <div style={{ '--right': bars?.[2] }} className='bar'></div>
                    <div className={`square ${step > 41 ? 'orange' : ''}`}>3</div>
                    <div style={{ '--right': bars?.[3] }} className='bar'></div> 
                    <div className={`square ${step > 52 ? 'orange' : ''}`}>R</div>
                </div>
                }
            </>
            }
        </div>
    )
}

export default Test
