import React, { useState, useRef, useEffect } from 'react'
import './styles.css'
import { useSelector } from 'react-redux'
import { useDetectClickOutside } from 'react-detect-click-outside'

const HeightInput = ({ id, label, defaultValue, disabled, setHeight }) => {
    const ref = useDetectClickOutside({ onTriggered: () => handleFocusLoss() })
    const feet = useRef()
    const inches = useRef(null)

    const lang = useSelector((state) => state.lang)

    const [done, setDone] = useState(false)

    const allowed = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete']

    const handleKeyDown = (e => {
        const backOrTab = allowed.find(i => i === e.key)
        const length = e.currentTarget?.value?.length
        if ((length >= 2 && e.currentTarget?.dataset?.ind === '1' && !backOrTab) || (length >= 1 && e.currentTarget?.dataset?.ind === '0' && !backOrTab)) {
            return e.preventDefault()
        } else if (length === 0 && e.currentTarget?.dataset?.ind === '1' && e.key === 'Backspace') {
            return feet.current?.focus()
        }
    })

    const handleChange = (e) => {
        // switch to input 2 on input 1 input
        if (e.currentTarget?.dataset?.ind === '0' && e.currentTarget?.value?.length > 0) {
            inches.current?.focus()
        }
        setHeight([Number(feet.current.value), Number(inches.current.value)])
    }
    
    const handleWrapClick = (e) => {
        setDone(true)
        if (e.target === feet.current) {
            feet.current?.focus()
        }
        else if (defaultValue) {
            inches.current?.focus()
        } else {
            feet.current?.focus()
        }
    }
    
    const handleFocusLoss = () => {
        if (!(feet.current.value.length > 0)) {
            setDone(false)
        }
    }

    useEffect(() => {
        if (defaultValue?.[0]) {
            feet.current.value = defaultValue?.[0]
            inches.current.value = defaultValue?.[1]
            setHeight([defaultValue?.[0], defaultValue?.[1]])
            setDone(true)
        }
    }, [defaultValue])

    return (
        <div className={`input-wrap height-input ${done ? 'done' : ''}`} onClick={!disabled ? handleWrapClick : () => {}} ref={ref}>
            <label>{label}</label>
            <input id={id + 'Feet'} type='number' className={done ? '' : 'hidden'} placeholder='0' ref={feet} defaultValue={defaultValue?.[0]} onKeyDown={handleKeyDown} onChange={handleChange} data-ind='0' disabled={disabled}/>
            <p>{done && lang?.inputs?.feet}</p>
            <input id={id + ''} type='number' className={done ? '' : 'hidden'} placeholder='0' ref={inches} defaultValue={defaultValue?.[1]} onKeyDown={handleKeyDown} onChange={handleChange} onBlur={handleFocusLoss} data-ind='1' disabled={disabled}/>
            <p>{done && lang?.inputs?.inches}</p>
        </div>
    )
}

export default HeightInput
