import * as API from '../api/index'
import * as actions from '../data/actions_types'

import { setAlert } from './alert'

export const createOrg = (_data, lang) => async (dispatch) => {
    try {
        const { data } = await API.createOrg(_data)

        dispatch({ type: actions.CREATE_ORG, payload: data })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

export const getMyOrg = () => async (dispatch) => {
    try {
        const { data } = await API.getMyOrg()

        dispatch({ type: actions.SET_ORG, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const setOrgName = (orgId, name, lang) => async (dispatch) => {
    try {
        const { data } = await API.setOrgName(orgId, name)

        dispatch({ type: actions.SET_ORG, payload: data})
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

export const leaveOrg = (id, adminData, lang) => async (dispatch) => {
    try {
        await API.leaveOrg(id, adminData)

        dispatch({ type: actions.LEAVE_ORG, payload: null })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

export const joinOrg = (id, adminData, lang) => async (dispatch) => {
    try {
        const { data } = await API.joinOrg(id, adminData)

        dispatch({ type: actions.SET_ORG, payload: data })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}