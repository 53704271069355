import axios from 'axios'
import { SERVER_URL } from '../data/env'

// base url
export const API = axios.create({ baseURL: SERVER_URL })

// middleware
API.interceptors.request.use((req) => {
    if (localStorage.getItem('token')) {
        req.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return req
})

// auth
export const createAccount = (data) => API.post('/user/create', data)
export const login = (data) => API.post('/user/login', data)
export const authVerify = (data) => API.patch('/user/verify', { v_code: data })
export const resendEmail = () => API.get('/user/verify')
export const verifyTokenValidity = (token) => API.get(`user/verify-token-validity/${token}`)

// contact form
export const sendMessage = (formData) => API.post('/contact-us', formData)

// profile
export const getMyProfile = () => API.get('/profile')
export const setBasics = (data) => API.put('/profile/basics', data)

// images
// export const uploadProfilePicture = (formData, axid) => API.post(`/profile/picture/${axid}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
export const uploadProfilePicture = (image, axid) => API.post(`/profile/picture/${axid}`, { imageString: image })

// sports
export const addSport = (formData) => API.post('/profile/sports', formData)
export const updateSport = (formData, ind) => API.patch(`/profile/sports/${ind}`, formData)
export const deleteSport = (ind) => API.delete(`/profile/sports/${ind}`)

// personality tests
export const setTests = (formData) => API.post('/profile/tests', formData)


// organizations
export const createOrg = (data) => API.post('/org', data)
export const getMyOrg = () => API.get('/org')
export const setOrgName = (id, name) => API.patch(`/org/${id}/name`, { name })
export const leaveOrg = (id, adminData) => API.patch(`/org/${id}/leave`, adminData)
export const joinOrg = (id, adminData) => API.post(`/org/${id}`, adminData)


// teams 
export const createTeam = (data) => API.post('/team', data)
export const getMyTeams = (userId) => API.get(`/team/${userId}`)
export const joinTeam = (teamId, tests) => API.patch(`/team/${teamId}`, { tests })
export const leaveTeam = (teamId) => API.delete(`/team/${teamId}`)


// sport objects
export const fetchSports = () => API.get('/sports')
