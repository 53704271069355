export const SET_NAV = "SET_NAV"
export const HIDE_NAV = "HIDE_NAV"

export const SET_LANG = "SET_LANG"

export const SET_ALERT = 'SET_ALERT'
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE"
export const SET_ALERT_STATUS = "SET_ALERT_STATUS"

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const AUTH_VERIFY = "AUTH_VERIFY"
export const VERIFY_TOKEN_VALIDITY = "VERIFY_TOKEN_VALIDITY"

export const SET_PROFILE = 'SET_PROFILE'

export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE'

export const CREATE_ORG = 'CREATE_ORG'
export const SET_ORG = 'SET_ORG'
export const LEAVE_ORG = 'LEAVE_ORG'

export const CREATE_TEAM = 'CREATE_TEAM'
export const SET_TEAM = 'SET_TEAM'
export const SET_TEAMS = 'SET_TEAMS'
export const LEAVE_TEAM = 'LEAVE_TEAM'