import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import logoutIcon from '../../../assets/images/icons/logout.png'
import OrgForm from './OrgForm'
import CenterModal from '../../modals/CenterModal/CenterModal'
import Team from './Team/Team'

import { setAlert, setAlertStatus } from '../../../actions/alert'
import { logout } from '../../../actions/auth'
import { setNav } from '../../../actions/nav'
import { getMyOrg, createOrg, setOrgName, leaveOrg, joinOrg } from '../../../actions/org'

const OrgAdmin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const lang = useSelector((state) => state.lang)
    const org = useSelector((state) => state.org)
    const auth = useSelector((state) => state.auth)

    const adminRef = useRef(null)

    const [noOrg, setNoOrg] = useState(0)
    const [showForm, setShowForm] = useState(false)
    const [affCode, setAffCode] = useState(false)

    const handleLogout = () => {
        dispatch(logout(navigate))
    }

    const handleCreateOrg = (e) => {
        e.preventDefault()

        const name = document.getElementById('org-input').value

        if (!name.length || name.length <= 0) 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4] }))

        dispatch(createOrg({ name, adminId: auth?.user?._id }))
    }

    const handleJoinOrg = (e) => {
        e.preventDefault()

        const adminData = { 
            adminId: auth?.user?._id,
            accType: auth?.user?.acc_type
        }

        const id = document.getElementById('org-input').value
        if (!id.length || id.length <= 0) 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4] }))

        dispatch(joinOrg(id, adminData, lang))
    }

    const handleChangeName = (e) => {
        e.preventDefault()

        const name = document.getElementById('org-input').value
        if (!name.length || name.length <= 0) 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4] }))

        dispatch(setOrgName(org?._id, name, lang))

        setShowForm(false)
    }

    const handleLeaveOrg = (e) => {
        e.preventDefault()

        const adminData = {
            adminId: auth?.user?._id,
            accType: auth?.user?.acc_type
        }

        return dispatch(setAlert({ 
            status: 'open',
            message: lang?.p?.quit_org,
            labels: { yes: lang?.buttons?.cancel, no: lang?.buttons?.leave },
            yesno: true,
            onNo: () => { dispatch(leaveOrg(org?._id, adminData, lang)); dispatch(setAlertStatus('closed')); setShowForm(false) }
        }))
    }

    // on mount
    useEffect(() => {
        // fetch profile document
        dispatch(getMyOrg(lang))

        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)

        // animation in
        setTimeout(() => {
            adminRef.current.style.display = 'block'
        }, 200)
        setTimeout(() => {
            adminRef.current.style.setProperty('--animation-delay', '0s')
        }, 2000)

        // setting currentPage to home and navbar to nav1
        dispatch(setNav('admin'))
    }, [dispatch])

    useEffect(() => {
        console.log(org)
    }, [org])

    return (
        <>
        <div id='admin' ref={adminRef}>
            <div className="header">
                <h1 id='tag'>
                    {auth?.user?.firstname} {auth?.user?.lastname}
                </h1>

                <div className="line h-line" id='header-line'></div>
            </div>

            <main>
                {org === null || showForm
                ? 
                <div className="card-01 no-org">
                    {noOrg === 0 ?
                    <div className="slide">
                        <h2>{lang?.kw?.join_your_org}</h2>
                        <p>{lang?.p?.acc_no_org}</p>
                        <div className='buttons'>
                            <button onClick={() => setNoOrg(1)}>{lang?.kw?.create_org} {'>'}</button>
                            <button onClick={() => setNoOrg(2)}>{lang?.kw?.join_your_org} {'>'}</button>
                        </div>
                    </div>
                    : noOrg === 1 ?
                    <div className="slide">
                        <h2>{lang?.kw?.create_org}</h2>
                        <OrgForm label={lang?.kw?.org_name} button={lang?.buttons?.create} onSubmit={handleCreateOrg} />
                        <p>{lang?.p?.your_org_exists?.[0]} &nbsp;<button onClick={() => setNoOrg(2)}>{lang?.buttons?.join_now}</button></p>
                    </div>
                    : noOrg === 2 ?
                    <div className="slide">
                        <h2>{lang?.kw?.join_your_org}</h2>
                        <OrgForm label={lang?.kw?.aff_code} button={lang?.buttons?.join} onSubmit={handleJoinOrg} />
                        <p>{lang?.p?.your_org_exists?.[1]} &nbsp;<button onClick={() => setNoOrg(1)}>{lang?.kw?.create_one_now}</button></p>
                    </div>
                    :
                    <div className="slide">
                        <h2>{lang?.kw?.change_name}</h2>
                        <OrgForm label={lang?.kw?.new_org_name} button={lang?.buttons?.save} onSubmit={handleChangeName} />
                        <p><button onClick={() => setShowForm(false)}>{lang?.buttons?.cancel}</button></p>
                    </div>
                    }
                </div>
                :
                <>
                {/* once everything is set */}
                <div className="card-01 org-card">
                    <h2>{org?.name}</h2>
                    <div className="right">
                        <button onClick={() => setAffCode(true)}>{lang?.kw?.show_aff_code}</button>
                        <button onClick={() => { setShowForm(true); setNoOrg(3) }}>{lang?.kw?.change_name}</button>
                        <button onClick={handleLeaveOrg}>{lang?.kw?.leave_org}</button>
                    </div>
                </div>

                {/* admins */}
                <h2>{lang?.kw?.admins}</h2>
                {org?.admins?.length > 0 && org?.admins.map((admin) => {
                    return (
                        <div className="card-01" key={admin?.axid}>
                            <h3>{admin?.firstname} {admin?.lastname} {auth?.user?.axid === admin?.axid && <span>({lang?.kw?.you})</span>}</h3>
                        </div>
                    )
                })}

                {/* coaches */}
                <h2>{lang?.kw?.coaches}</h2>
                {org?.coaches?.length > 0 ? org?.coaches.map((admin) => {
                    return (
                        <div className="card-01" key={admin?.axid}>
                            <h3>{admin?.firstname} {admin?.lastname}</h3>
                        </div>
                    )
                })
                :
                <p>{lang?.p?.no_coach_in_org}</p>
                }

                {/* teams */}
                <h2>{lang?.kw?.teams}</h2>
                <Team teams={org?.teams} />
                </>
                }

            </main>

            <div className="logout">
                <button onClick={handleLogout}>{lang?.buttons?.logout} <img src={logoutIcon} alt="logout" height={12}/></button>

                <div className="line h-line" id='footer-line'></div>
            </div>
            
            <div className="line v-line" id='left-line'></div>
            <div className="line v-line" id='right-line'></div>
        </div>
        {affCode && 
        <CenterModal closeModal={() => setAffCode(false)}>
            <h2>{lang?.p?.aff_code_helper?.[0]}</h2>
            <p>{lang?.p?.aff_code_helper?.[1]}</p>
            <p>{lang?.p?.aff_code_helper?.[2]}</p>
            <p className='fs-accent'>{org?._id}</p>
        </CenterModal>
        }
        </>
    )
}

export default OrgAdmin