import React, { useEffect, useRef, useState } from 'react'
import './styles.css'

const Block = ({ title, text, children, left, ...rest }) => {
    const lineRef = useRef()

    const [visible, setVisible] = useState(false) 

    useEffect(() => {
        const elements = document.getElementsByClassName('scroll-element')

        let options = { root: document.getElementById('root'), rootMargin: '300px 0px -5% 0px', threshold: 0.1}
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((e) => {
                if (e.isIntersecting) {
                    e.target.classList.remove('out')
                }
            })
        }, options)

        for (let i = 0; i < elements.length; i++) {
            observer.observe(elements[i])
        }
        
    }, [])

    return (
        <div className='masks-block' {...rest}>
            <h2 className='scroll-element out'>{title}</h2>
            {text && text.map((e, ind) => {
                return (
                    <p key={ind} className='scroll-element out'>{e}</p>
                )
            })}
            {children && <div className="children scroll-element out">
                {children}
            </div>}

            <div className={`special-line ${left ? 'left' : 'right'} scroll-element out`} ref={lineRef} ></div>
        </div>
    )
}

export default Block

