import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import logoSmall from '../../../assets/images/logoSmall.png'
import DelayLink from '../../tools/DelayLink/DelayLink'

import { setNav } from '../../../actions/nav'

const ProfileNav = () => {
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.lang)
    const nav = useSelector((state) => state.nav)
    const auth = useSelector((state) => state.auth)
    
    // change page 
    const changePage = (el) => {
        // trigger current page's animation out
        document.getElementById(nav?.currentPage).classList.add('out')
    }

    return (
        <nav id='profile-nav'>
            <DelayLink to='/' onDelayStart={changePage}>&lt; {lang?.buttons?.home}</DelayLink>

            <Link to='/' id='logo'><img src={logoSmall} alt="ATHLETE-X" /></Link>

            {auth?.user?.acc_type !== 'admin' && <Link to='/profile/settings'>{lang?.buttons?.settings} &gt;</Link>}
        </nav>
    )
}

export default ProfileNav
