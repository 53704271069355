import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DDInput from '../../tools/DDInput/DDInput'
import Input from '../../tools/Input/Input'
import StateButton from '../../tools/StateButton/StateButton' 

import { addSport, updateSport } from '../../../actions/profile'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import { joinTeam } from '../../../actions/teams'

const InputRoulette = ({ ddSports, sports, next, i, selected, setSelected, mod, close }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const lang = useSelector((state) => state.lang)
    const eng = useSelector((state) => state.lang.name) === 'eng' ? true : false
    const s = useSelector((state) => state.profile?.sports)?.[mod]
    const p = useSelector((state) => state.profile)
    const teams = useSelector((state) => state.teams)
    const teamName = teams?.[(teams?.length - 1) || 0]?.name
    
    const roulette = useRef(null)

    const [status, setStatus] = useState('initial')
    const [down, setDown] = useState(false)
    const [joined, setJoined] = useState('initial')
    const [specialties, setSpecialties] = useState(null)
    const [categories, setCategories] = useState(null)
    const [form, setForm] = useState({ sport: null, specialty_fr: null, specialty_eng: null, category: null, team_id: null, team_name: null, org: null })

    const handleSportSelection = (e) => {
        const sport = sports.find(s => s._id === e?.target?.dataset?.value)
        setSelected(sport)
        // set selected sport in form object
        setForm(prev => { return { ...prev, sport: { _id: sport?._id, name_fr: sport?.name_fr, name_eng: sport?.name_eng } } })

        setInputFields(sport)
    }

    const setInputFields = (selectedSport) => {
        if (!sports) return 
        const sport = selectedSport ? selectedSport : sports?.find(e => e?._id === s?.sport?._id)

        if (sport?.specialties?.[0] === 'dna' && sport?.categories?.[0] === 'dna') {
            setForm(prev => { return { ...prev, specialty_eng: 'dna', specialty_fr: 'dna', category: 'dna' } })
            return next(3)
        } else if (sport?.specialties?.[0] === 'dna') {
            setForm(prev => { return { ...prev, specialty_eng: 'dna', specialty_fr: 'dna' } })
            const arr2 = []
            for (let ind = 0; ind < sport?.categories?.length || 0; ind++) {
                if (sport?.categories?.[ind] === 'dna') {
                    next()
                    return setCategories([{ value: 0, text: 'Does not apply' }])
                }
                arr2[ind] = { value: ind, text: sport?.categories?.[ind] }
            }
            setCategories(arr2)
            return next(2)
        }

        const arr = []
        for (let ind = 0; ind < sport?.specialties?.length || 0; ind++) {
            arr[ind] = { value: ind, text: eng ? sport?.specialties?.[ind]?.eng : sport?.specialties?.[ind]?.fr }
        }
        setSpecialties(arr)

        const arr2 = []
        for (let ind = 0; ind < sport?.categories?.length || 0; ind++) {
            arr2[ind] = { value: ind, text: sport?.categories?.[ind] }
        }
        setCategories(arr2)
        next()
    }

    const handlePositionSelection = (e) => {
        const position = selected && selected?.specialties[0] !== 'dna' ? selected?.specialties?.[e?.target?.dataset?.value] : null

        if (position) {
            setForm(prev => { return { ...prev, specialty_eng: position?.eng, specialty_fr: position?.fr } })
        }

        if (form.category === 'dna') next(2)
        else next()
    }

    const handleCategorySelection = (e) => {
        const category = selected && selected?.categories?.[0] !== 'dna' ? selected?.categories?.[e?.target?.dataset?.value] : null

        if (category) {
            setForm(prev => { return { ...prev, category } })
        }

        next()
    }

    const handleSkip = () => {
        setForm(prev => { return { ...prev, team_id: 'skipped' } })
        
        next()
    }

    const handleJoin = () => {
        setDown(true)
    }

    const handleCancelJoin = () => {
        setDown(false)
        setTimeout(() => handleSkip(), 400)
    }

    const handleJoinSubmit = (e) => {
        e.preventDefault()

        if (p?.tests?.status === 'todo') 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[25] }))

        const affCode = document.getElementById('team-input').value
        console.log(affCode)

        setJoined('loading')
        dispatch(joinTeam(affCode, p?.tests, lang, setJoined, 'athlete', handleCancelJoin))
    }

    const handleSubmit = () => {
        setStatus('loading')
        dispatch(addSport(form, setStatus, lang))
    }

    const handleUpdate = () => {
        setStatus('loading')
        dispatch(updateSport(form, `${mod}`, setStatus, lang))
    }

    // set default value if isMod
    useEffect(() => {
        if (!isNaN(mod)) {
            // set selected sport to previous sport
            if (!selected) {
                const sel = sports?.find(e => e._id === s?.sport?._id)
                setSelected(sel)
            }
            // set form values to previous
            setForm({ sport: s?.sport, 
                      specialty_eng: s?.specialty_eng, 
                      specialty_fr: s?.specialty_fr, 
                      category: s?.category,
                      team_id: s?.team_id,
                      team_name: s?.team_name,
                      org: s?.org })
            setInputFields()
        }
    }, [mod, sports])

    // update css variable with form steps 
    useEffect(() => {
        roulette.current?.style?.setProperty('--step', i) 
    }, [i])

    // close modal and navigate to sttings on form submission
    useEffect(() => {
        if (status === 'success') 
            setTimeout(() => {
            close()
            navigate('/profile/settings#sports')
        }, 100)
    }, [status])
    
    return (
        <>
        <div className={`input-roulette ${down ? 'down' : ''}`} ref={roulette}>
            <div className={`part ${i > 1 ? 'hidden' : ''} ${i === 0 ? 'active' : ''}`}>
                <DDInput id='ssport' label='Sport' options={ddSports} onOptionClick={handleSportSelection} setValue={setSelected} defaultValue={form?.sport ? eng ? form?.sport?.name_eng : form?.sport?.name_fr : null} disabled/>
            </div>

            <div className={`part ${i >= 3 ? 'hidden' : ''} ${i === 1 ? 'active' : ''}`}>
                {form?.specialty_eng !== 'dna' 
                ?
                <DDInput id='specialty' label={lang?.kw?.position} options={specialties} onOptionClick={handlePositionSelection} defaultValue={form?.specialty_eng ? eng ? form?.specialty_eng : form?.specialty_fr : null} disabled/>
                :
                <p>{lang?.p?.no_position}</p>
                }
            </div>

            <div className={`part ${i === 2 ? 'active' : ''}`}>
                {form?.category !== 'dna' 
                ?                
                <DDInput id='category' label={lang?.kw?.category} options={categories} onOptionClick={handleCategorySelection} defaultValue={form?.category} disabled/>
                :
                <p>{lang?.p?.no_category}</p>
                }
            </div>

            <div className={`part ${i < 2 ? 'hidden' : ''} ${i === 3 ? 'active' : ''}`}>
                {!down && joined === 'initial' && <><button className="border-button full orange" onClick={handleJoin}>{lang?.buttons?.join}</button>
                <button className="border-button full" onClick={handleSkip}>{lang?.buttons?.skip}</button></>}
                {down && joined === 'initial' && <button className="border-button full" onClick={handleCancelJoin}>{lang?.buttons?.cancel}</button>}
                {joined === 'success' && <div className='border-button full'>{teamName}</div>}
            </div>

            <div className={`part ${i < 3 ? 'hidden' : ''} ${i === 4 ? 'active' : ''}`}>
                <StateButton orange onClick={isNaN(mod) ? handleSubmit : handleUpdate } initial={lang?.buttons?.save} success={lang?.buttons?.saved} state={status}/>
            </div>
        </div>
        {down && joined !== 'success' &&
        <form className='join' onSubmit={handleJoinSubmit}>
            <p>{lang?.p?.enter_code_join}</p>
            <Input id='team-input' label={lang?.kw?.affiliation_code} />
            <StateButton type='submit' initial={lang?.buttons?.join} success={lang?.buttons?.success} state={joined} orange/>
        </form>}
        </>
    )
}

export default InputRoulette
