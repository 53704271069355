import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import Banner from './Banner/Banner'


const Team = ({ teams }) => {
    const scrollRef = useRef(null)

    const lang = useSelector((state) => state.lang)

    const [i, setI] = useState(0)

    const next = () => {
        setI(prev => prev < teams?.length - 1 ? prev + 1 : 0)
    }

    const prev = () => {
        setI(prev => prev > 0 ? prev - 1 : teams?.length - 1)
    }

    useEffect(() => {
        if (document.getElementById('banners'))
        document.getElementById('banners').style.setProperty('--nb-banners', (teams?.[i]?.coaches?.length || 0) + (teams?.[i]?.athletes?.length || 0))
    }, [i, teams])

    return (
        <>
        <div className="scroll-ref"  ref={scrollRef} ></div>
        {!teams?.length > 0
        ?
            <div className="no-team mt-75">
                <p>{lang?.p?.org_no_team}</p>
            </div>
        :
            <div className="teams">
                <div className="controls">
                    <h2>{teams?.[i]?.name}</h2>
                    <div style={{ display: 'flex' }}>
                        <button onClick={prev}>
                            <svg width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M-1.71031e-08 16.1719L15.9106 32.0825L20 27.9931L8.17876 16.1719L20 4.35066L15.9106 0.261281L-1.71031e-08 16.1719Z" fill="hsl(var(--bg-accent) / 0.6)"/>
                            </svg>
                        </button>
                        <button onClick={next}>
                            <svg width="21" height="33" viewBox="0 0 21 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.3 16.1719L4.38937 32.0825L0.299988 27.9931L12.1212 16.1719L0.299987 4.35066L4.38936 0.261281L20.3 16.1719Z" fill="hsl(var(--bg-accent) / 0.6)"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='team'>                    
                    <motion.ul transition={{ duration: .6, type: 'spring' }} id='banners'>
                        <AnimatePresence>
                        {teams?.[i]?.coaches && teams?.[i]?.coaches.map((member, ind) => {
                            return (
                                <Banner member={member} ind={ind} key={member?.id?.axid}/>
                            )
                        })}
                        {teams?.[i]?.athletes && teams?.[i]?.athletes.map((member, ind) => {
                            return (
                                <Banner member={member} ind={ind + (teams?.[i]?.coaches?.length || 0)} key={member?.id?.axid}/>
                            )
                        })}
                        </AnimatePresence>
                    </motion.ul>
                    <p className='hold-shift'>{lang?.p?.h_scroll?.[0]} <code>shift</code> {lang?.p?.h_scroll?.[1]}</p>
                </div>   
            </div>

        }
        </>
    )
}

export default Team
