import React, { useState } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";

import roundLoader from "../../../assets/images/icons/roundLoader.png";
import { uploadProfilePicture } from "../../../actions/profile";
import { setAlert } from "../../../actions/alert";

const FileInput = ({ id, label }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);
  const auth = useSelector((state) => state.auth);

  const [state, setState] = useState("initial");

  const handleSave = (file) => {
    if (!file) {
      setState("initial");
      return dispatch(
        setAlert({ status: "open", message: lang?.errors?.[11] })
      );
    }

    // create image base64 string
    fileToBase64(file, (base64String) => {
      if (base64String) {
        // Use the base64String in your dispatch function
        dispatch(uploadProfilePicture(base64String, auth?.user?.axid, lang, setState));
      } else {
        setState("initial");
        dispatch(setAlert({ status: "open", message: "Unable to read the file as base64." }));
      }
    })

    // dispatch(uploadProfilePicture(base64String, auth?.user?.axid, lang, setState));
  };

  const fileToBase64 = (file, callback) => {
    const reader = new FileReader();
  
    reader.onload = function(event) {
      const base64String = event.target.result.split(',')[1]; // Extract the base64 data from the result
      callback(base64String);
    };
  
    reader.onerror = function(error) {
      console.error('Error reading the file: ', error);
      callback(null);
    };
  
    reader.readAsDataURL(file);
  }

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeMb = 10;
  
    // check file size
    if (selectedFile && selectedFile.size > maxSizeMb * 1024 * 1024) {
      e.preventDefault();
      dispatch(setAlert({ status: 'open', message: lang?.errors?.[15] }))
      return;
    }

    setState("changed");
    handleSave(selectedFile)
  };

  return (
    <div className="file-input">
      <label htmlFor={id}>
        {state === "changed" 
        ? 
        ( lang?.buttons?.save ) 
        : state === "loading" ? 
        ( <img src={roundLoader} alt="Loading Spinner" /> ) 
        : state === "saved" ? 
        ( lang?.buttons?.saved ) 
        : 
        ( label )
        }
      </label>
      <input
        id={id}
        type="file"
        accept="image/*"
        onChange={handleChange}
        onBlur={() => {} /*handleBlur*/}
        disabled={state === 'initial' ? false : true}
      />
    </div>
  );
};

export default FileInput;
