import React from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'

import { setLang } from '../../../actions/lang'

const LangModal = ({ closeModal }) => {
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.lang) 
    
    const handleLangSelection = (_lang) => {
        if (lang?.name === _lang) {
            localStorage.setItem('lang', _lang)
            closeModal()
        }
        else {
            dispatch(setLang(_lang))
            closeModal()
        }
    }

    return (
        <div id='lang-modal' className='center-modal dont'>
            <div className={`modal dont`}>
                <h2>{lang?.kw?.select_lang}</h2>
                <button className="card-01" onClick={() => handleLangSelection('eng')}>
                    ENG
                </button>
                <button className="card-01" onClick={() => handleLangSelection('fr')}>
                    FR
                </button>   
            </div>
        </div>
    )
}

export default LangModal
