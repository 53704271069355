import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMyProfile } from '../../../actions/profile'


const Intro = ({ step, setStep }) => {
    const dispatch = useDispatch()
    
    const lang = useSelector((state) => state.lang)
    const auth = useSelector((state) => state.auth)
    const profile = useSelector((state) => state.profile)

    const start = () => {
        setStep(prev => prev + 0.5)
    }

    useEffect(() => {
        if (auth.logged) {
            dispatch(getMyProfile(lang))
        }
    }, [auth])

    useEffect(() => {
        console.log(profile)
    }, [profile])

    return (
        <div className={`intro ${step === 0.5 ? 'out' : ''}`}>
            <button 
                onMouseDown={start}
            >
                {lang?.buttons?.start}
            </button>

            <svg 
                width="312" 
                height="358" 
                viewBox="0 0 312 358" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0.865234 89.9342L156 0.864822L311.134 89.9342V268.066L156 357.135L0.865234 268.066V89.9342Z" stroke="#FF7A00" strokeWidth="2"/>
            </svg>
            <svg
                width="312" 
                height="358" 
                viewBox="0 0 312 358" 
                fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                <path d="M0.865234 89.9342L156 0.864822L311.134 89.9342V268.066L156 357.135L0.865234 268.066V89.9342Z" stroke="#FF7A00" strokeWidth="2"/>
            </svg>
        </div>
    )
}

export default Intro
