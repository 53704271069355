import React, { useEffect } from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setNav } from '../../../actions/nav'

const PageNotFound = () => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.lang)
  
  useEffect(() => {
    dispatch(setNav('404'))   
  }, [])
  
  return (
    <div id='page-not-found'>
      <h1>404</h1>
      <p>{lang?.p?.page_not_exist}</p>

      <Link to='/' className='mt-75 fs-accent'>{lang?.kw?.return_home}</Link>
    </div>
  )
}

export default PageNotFound
