import { SET_NAV, HIDE_NAV } from '../data/actions_types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (navbar = { currentPage: 'home', previousPage: 'home', nav: 'main-nav' }, action) => {
    switch (action.type) {
        case SET_NAV:
            if (action.payload !== navbar.currentPage) {
                if (action?.payload === 'profile' || action?.payload === 'admin')
                    return { currentPage: action?.payload, previousPage: navbar?.currentPage, nav: 'profile-nav' }
                else if (action?.payload === 'test') 
                    return { currentPage: action?.payload, previousPage: navbar?.currentPage, nav: 'none' }

                return { currentPage: action?.payload, previousPage: navbar?.currentPage, nav: 'main-nav' }
            } else {
                return navbar
            }
            case HIDE_NAV:
                return { ...navbar, nav: 'none' }
        default:
            return navbar
    }
}