import React from 'react'
import { useDispatch } from 'react-redux'
import './styles.css'

import { setAlertStatus } from '../../../actions/alert'

const AlertModal = ({ message, onClose, labels, yesno, onYes, onNo }) => {
    const dispatch = useDispatch()

    const handleYesClick = () => {
        dispatch(setAlertStatus('closed'))
        if (onYes) onYes()
    }

    const handleClose = () => {
        dispatch(setAlertStatus('closed'))
        if (onNo) onNo()

        if (onClose) onClose()
    }

    return (
        <div className='alert-modal dont'>
            <div className="modal dont">
                <p className='dont'>{message}</p>
                {yesno 
                ? 
                <div className='buttons dont'>
                    <button className='dont' onClick={handleYesClick}>{labels?.yes}</button>
                    <button className='light dont' onClick={handleClose}>{labels?.no}</button>
                </div>
                :
                <button className='dont' onClick={handleClose}>OK</button>
                }
            </div>
        </div>
    )
}

export default AlertModal
