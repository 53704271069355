import React, { useEffect, useRef } from 'react'
import './styles.css'

const TitleCarousel = ({ data, i }) => {
    const inner = useRef(null)
    
    useEffect(() => {
        inner.current?.style?.setProperty('--step', i) 
    }, [i])

    return (
        <div className='title-carousel'>
            <div className="inner" ref={inner}>
                {data && data.map((text, ind) => {
                    return (
                        <h1 key={ind}>{text}</h1>
                    )
                })}
            </div>
        </div>
    )
}

export default TitleCarousel
