import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'

import mask from '../../../assets/images/icons/mask.png'

import Block from './Block/Block'
import DelayLink from '../../tools/DelayLink/DelayLink'

import { setNav } from '../../../actions/nav'

const Masks = () => {
    const lang = useSelector((state) => state.lang)
    const masks = useRef(null)
    const dispatch = useDispatch()

    const changePage = (e) => {
        // trigger current page's animation out
        masks.current.classList.add('out')
        // change currentPage state
        dispatch(setNav(e?.target?.dataset?.page))
    }

    useEffect(() => {
        const blocks = document.querySelectorAll('.masks-block')
        setTimeout(() => {
            for (let i = 0; i < blocks.length; i++) {
                blocks[i].style.display = 'block'
            }
        }, 800)
    }, [])

    // on mount
    useEffect(() => {
        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)

        // animation in
        setTimeout(() => {
            masks.current.style.display = 'block'
        }, 500)
        // setting currentPage to home and navbar to nav1
        dispatch(setNav('masks'))
    }, [dispatch])

    return (
        <div id='masks' ref={masks}>
            <div className="masks">
                <img src={mask} alt="ATHLETE-X Masks Icon" />

                <div className="line v-line" id='line-1'></div>
                <div className="line v-line" id='line-2'></div>
            </div>
            <div className="header">
                <h1>{lang?.h?.masks_h1}</h1>
                <p>{lang?.p?.masks_subtitle}</p>

                <div className="line h-line" id='line-3'></div>
                <div className="line h-line" id='line-4'></div>
            </div>
            <main>
                <Block title={lang?.h?.masks_b1} text={lang?.p?.masks_b1} left/>

                <Block title={lang?.h?.masks_b3} text={lang?.p?.masks_b3} left/>

                <Block title={lang?.h?.masks_b2} text={lang?.p?.masks_b2}/>

                <Block title={lang?.h?.masks_b4} text={lang?.p?.masks_b4}>
                    <DelayLink to='/take-the-test' data-page='test' onDelayStart={changePage} className='border-button full orange'>{lang?.buttons?.take_test}</DelayLink>
                </Block>

                <p className='copyrights'>{lang?.p?.copyrights}</p>

                <div className="line v-line" id='line-5'></div>
                <div className="line v-line" id='line-6'></div>
            </main>
        </div>
    )
}

export default Masks
