import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import { setAlert } from '../../../actions/alert'

const PartThree = ({ step, setStep, setResults, submitTest }) => {
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.lang)
    
    const [selected, setSelected] = useState(null)

    const next = () => {
        if (!selected) return dispatch(setAlert({ status: 'open', message: lang?.errors?.[17] }))
        
        // add points to score
        setResults(prev => {
            const old = JSON.parse(JSON.stringify(prev))
            console.log(selected?.score)
            old.grit += Number(selected?.score) ? Number(selected.score) : console.log('errreeur')

            return old
        })

        // reset for next block
        setSelected(null)

        // trigger test submission on final step
        if (step === 52) submitTest()
        
        // set step to x.5
        setStep(prev => prev + 0.5)
    }

    return (
        <>
        {(step === 42 || step === 42.5) 
        ?
        <div className={`part-page ${step % 1 !== 0 ? 'out' : ''}`}>
            <h1>{lang?.kw?.part} <span>3</span></h1>

            <p>{lang?.grit_test?.explanations}</p>

            <button 
                className='border-button orange min'
                onClick={() => setStep(prev => prev + 0.5)}
            >
                {lang?.buttons?.got_it}
            </button>
        </div>
        :
        <div className={`part three ${step % 1 !== 0 ? 'out' : ''}`}> 
            <h2>{lang?.grit_test?.questions?.[Math.floor(step) - 43]?.text}</h2>

            <button 
                className={`next-button border-button ${selected ? 'orange' : ''}`} 
                onClick={next}
            >
                {lang?.buttons?.next}
            </button>

            <main>
                <motion.div className="inner" layout transition={{ duration: 0.5, type: 'tween' }}>
                    <motion.button transition={{ duration: 0.5, type: 'tween' }}
                        className={selected?.ind === 1 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 1, score: lang?.grit_test?.questions?.[Math.floor(step) - 43]?.scores?.[0] })}
                    >
                        <h3>{lang?.grit_test?.choices?.[0]}</h3>
                    </motion.button>
                    <motion.button transition={{ duration: 0.5, type: 'tween' }}
                        className={selected?.ind === 2 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 2, score: lang?.grit_test?.questions?.[Math.floor(step) - 43]?.scores?.[1] })}
                    >
                        <h3>{lang?.grit_test?.choices?.[1]}</h3>
                    </motion.button>
                    <motion.button transition={{ duration: 0.5, type: 'tween' }}
                        className={selected?.ind === 3 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 3, score: lang?.grit_test?.questions?.[Math.floor(step) - 43]?.scores?.[2] })}
                    >
                        <h3>{lang?.grit_test?.choices?.[2]}</h3>
                    </motion.button>
                    <motion.button transition={{ duration: 0.5, type: 'tween' }}
                        className={selected?.ind === 4 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 4, score: lang?.grit_test?.questions?.[Math.floor(step) - 43]?.scores?.[3] })}
                    >
                        <h3>{lang?.grit_test?.choices?.[3]}</h3>
                    </motion.button>
                    <motion.button transition={{ duration: 0.5, type: 'tween' }}
                        className={selected?.ind === 5 ? 'selected' : ''} 
                        onClick={() => setSelected({ ind: 5, score: lang?.grit_test?.questions?.[Math.floor(step) - 43]?.scores?.[4] })}
                    >
                        <h3>{lang?.grit_test?.choices?.[4]}</h3>
                    </motion.button>
                </motion.div>
            </main>
        </div>
        }
        </>
    )
}

export default PartThree
