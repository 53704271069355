import React from 'react'
import './styles.css'

import roundLoader from '../../../assets/images/icons/roundLoader.png'

const FormButton = ({ text, state, orange, ...rest }) => {


    return (
        <div {...rest} className={`form-button-wrap ${state === 'loading' ? 'loading' : ''} ${state === 'sucess' ? 'success' : ''}`}>
            <button type='submit' className={`border-button full ${orange ? 'orange' : ''}`}>{state === 'success' ? text?.success : text?.initial}</button>
            {state === 'loading' && <img src={roundLoader} alt='Loading Spinner' />}
        </div>
    )
}

export default FormButton