import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Input from '../../tools/Input/Input'
import StateButton from '../../tools/StateButton/StateButton'

import { setAlert } from '../../../actions/alert'

const OrgForm = ({ label, button, onSubmit }) => {
    const dispatch = useDispatch()
    
    const auth = useSelector((state) => state.auth)
    const lang = useSelector((state) => state.lang)

    const [state, setState] = useState('initial')

    return (
        <form onSubmit={onSubmit}>
            <Input id='org-input' label={label} />
            <StateButton state={state} initial={button} success={button} />
        </form>
    )
}

export default OrgForm
