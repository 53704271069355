import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'

import InputRow from '../../tools/InputRow/InputRow'
import Input from '../../tools/Input/Input'
import TextArea from '../../tools/TextArea/TextArea'
import Lined from '../../tools/Lined/Lined'
import FormButton from '../../tools/FormButton/FormButton'

import { setNav } from '../../../actions/nav'
import { sendMessage } from '../../../api'
import { setAlert } from '../../../actions/alert'

const ContactUs = () => {
    // hooks
    const dispatch = useDispatch()
    const contactUs = useRef(null)
    const contactForm = useRef(null)
    const lang = useSelector((state) => state.lang)

    const [process, setProcess] = useState('initial')

    useEffect(() => {
        // set lines css variables
        const rect = contactForm.current.getBoundingClientRect()
        const lines = document.querySelectorAll('.input-line')
        lines.forEach(e => {
            e.style.setProperty('--left', rect.left)
        });

        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)

        // animation in
        setTimeout(() => {
            contactUs.current.style.display = 'block'
        }, 500)
        // setting currentPage to home and navbar to nav1
        dispatch(setNav('contact-us'))
    }, [])
    
    useEffect(() => {
        const handleResize = window.addEventListener('resize', () => {
            if (contactForm?.getBoundingClientRect()) {
                const rect = contactForm.current.getBoundingClientRect()
                const lines = document.querySelectorAll('.input-line')
                lines.forEach(e => {
                    e.style.setProperty('--left', rect.left)
                });
            }
        })

        return () => {
            document.removeEventListener('resize', handleResize)
        }
    })

    const getValue = (name) => {
        if (document.getElementById(name).value)
            return document.getElementById(name).value
        else 
            return 'Error retrieving field value'
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setProcess('loading')
        const formData = { _firstname: getValue('firstname'), _lastname: getValue('lastname'), _email: getValue('email'), _message: getValue('message') }
        
        try {
            const result = await sendMessage(formData)
            setProcess('success')
        } catch (error) {
            setProcess('initial')
            dispatch(setAlert({ status: 'open', message: lang?.form_buttons?.contact_us?.failure }))
            console.log('after dispatch')
        }
    }

    return (
        <div id='contact-us' ref={contactUs}>
            <div className="form-cont">
                <div className="form-header">
                    <h1 className='form-title'>{lang?.h?.contact_us_h1}</h1>
                </div>
                <form ref={contactForm} onSubmit={handleSubmit}>
                    <InputRow>
                        <Lined><Input id='firstname' label={lang?.inputs?.firstname}/></Lined>
                        <Lined mobonly><Input id='lastname' label={lang?.inputs?.lastname}/></Lined>
                    </InputRow>

                    <InputRow>
                        <Lined><Input id='email' label={lang?.inputs?.email} type='email'/></Lined>
                    </InputRow>

                    <InputRow>
                        <Lined><TextArea id='message' label={lang?.inputs?.message}/></Lined>
                    </InputRow>

                    <InputRow>
                        <Lined top='0'><FormButton text={lang?.form_buttons?.contact_us} state={process}/></Lined>
                    </InputRow>
                </form>

                <div className="line v-line" id="contact-us-line-1"></div>
                <div className="line v-line" id="contact-us-line-2"></div>
            </div>

            <p className='copyrights'>{lang?.p?.copyrights}</p>
        </div>
    )
}

export default ContactUs
