import { combineReducers } from "@reduxjs/toolkit";

// importing all reducers
import nav from './nav'
import lang from './lang'
import alert from './alert'
import auth from './auth'
import profile from './profile'
import org from './org'
import teams from './teams'

// exporting the reducer object
export default combineReducers({ nav, lang, alert, auth, profile, org, teams })