import React from 'react'
import './styles.css'
import { useSelector } from 'react-redux'

import xIcon from '../../../assets/images/icons/orangeXIcon.png'

const CenterModal = ({ closeModal, children, maxSize }) => {
    const lang = useSelector((state) => state.lang) 

    return (
        <div className='center-modal dont'>
            <div className={`modal ${maxSize ? 'max-sized' : ''} dont`}>
                {children}

                <button className="close dont" onClick={closeModal}>
                    <p className='dont'>{lang?.buttons?.close}</p>
                    <img className='dont' src={xIcon} alt="x" />
                </button>                
            </div>
        </div>
    )
}

export default CenterModal
