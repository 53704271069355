import React from 'react'
import './styles.css'
import { useSelector } from 'react-redux'

import TextArea from '../../../tools/TextArea/TextArea'

import { formatDate, getAge } from '../../../../utilities/formats'

const Basics = () => {
    const lang = useSelector((state) => state.lang)
    const p = useSelector((state) => state.profile)

    return (
        <ul className='basics'>
            <li className='hl'>
                <p>{lang?.basics.age}</p>
                    <p>{getAge(p.birth_date)}</p>
            </li>
            <li>
                <p>{lang?.basics.gender}</p>
                <p>{p?.gender}</p>
            </li>            
            {p?.acc_type === 'athlete' && <li className='hl'>
                <p>{lang?.basics.height}</p>
                <p>{p?.height?.[0] ? `${p?.height?.[0]} ' ${p?.height?.[1]}` : ''}</p>
            </li>}            
            {p?.acc_type === 'athlete' && <li>
                <p>{lang?.basics.weight}</p>
                <p>{p?.weight ? p?.weight + 'lbs' : ''}</p>
            </li>}
            <li className='hl'>
                <p>{lang?.basics.birth_date}</p>
                <p>{p?.birth_date ? formatDate(p?.birth_date) : ''}</p>
            </li>
            <li>
                <p>{lang?.basics.birth_place}</p>
                <p>{p?.birth_place}</p>
            </li>
            <li className='hl'>
                <p>{lang?.basics.residency}</p>
                <p>{p?.residency}</p>
            </li>
            {p?.acc_type === 'coach' &&
            <li className=''>
                <p>{lang?.basics.certifications}</p>
                <p>{p?.certifications ? p?.certifications : '-'}</p>
            </li>
            }

            <div className="line v-line" id='basics-line'></div>
        </ul>
    )
}

export default Basics
