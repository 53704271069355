import * as actions from '../data/actions_types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (org = null, action) => {
    switch (action.type) {
        case actions.CREATE_ORG:
            return action?.payload
        case actions.SET_ORG:
            return action?.payload
        case actions.LEAVE_ORG:
            return null
        case actions.CREATE_TEAM:
            console.log(action?.payload?.org)
            return action?.payload?.org
        default:
            return org
    }
}