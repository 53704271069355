import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import './styles.css'

import InputRow from '../../../tools/InputRow/InputRow'
import Lined from '../../../tools/Lined/Lined'
import FormButton from '../../../tools/FormButton/FormButton'

import { setNav } from '../../../../actions/nav'
import { setAlert } from '../../../../actions/alert'
import { verify } from '../../../../actions/auth'
import { resendEmail } from '../../../../api'
import DelayLink from '../../../tools/DelayLink/DelayLink'

const Verify = () => {
  const lang = useSelector((state) => state.lang)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const form = useRef()
  const authVerify = useRef()
  const line1 = useRef()
  const line2 = useRef()

  const [process, setProcess] = useState('initial')

  const setLinesLeft = () => {
    const rect = form.current.getBoundingClientRect()
    const lines = document.querySelectorAll('.input-line')
    lines.forEach(e => {
        e.style.setProperty('--left', rect.left)
    });
  }

  const vLinesLeft = () => {
    const formButton = document.getElementById('auth-button')
    const rect = formButton.getBoundingClientRect()
    line1.current.style.setProperty('--left', Math.floor(rect.left * 100) / 100)
    line2.current.style.setProperty('--left', Math.floor(rect.right * 100) / 100)
  }


  useEffect(() => {
    // check is use already verified
    console.log('VERIFY COMPONENT MOUNTED')
    if (auth && auth.logged === false) {
      console.log('Redirect => AUTH')
      return navigate('/auth', { replace: true })
    } 
    else if (auth && auth?.user?.verified === 'verified') {
      console.log('Redirect => PROFILE')
      return navigate('/profile', { replace: true })
    }

    // set lines css variables
    setLinesLeft()
    vLinesLeft()

    // reset scroll to 0
    document.getElementById('root').scrollTo(0, 0)

    // animation in
    setTimeout(() => {
        authVerify.current.style.display = 'block'
    }, 500)
    // setting currentPage to home and navbar to nav1
    dispatch(setNav('auth-verify'))
  }, [])


  useEffect(() => {
    const handleResize = window.addEventListener('resize', () => {
        setLinesLeft()
        vLinesLeft()
    })

    return () => {
        document.removeEventListener('resize', handleResize)
    }
  }, [])

  const gv = (id) => {
    return document.getElementById(id)?.value
  }

  const focusNext = () => {
    const inputs = document.querySelectorAll('input')
    const currInput = document.activeElement
    const currInputIndex = parseInt(currInput.getAttribute('id')[2])
    const nextinputIndex = currInputIndex === 6 ? 5 : currInputIndex // (currInputIndex + 1) % inputs.length
    const input = inputs[nextinputIndex]
    input.focus()
  }

  const handleKeyDown = (e) => {
    const num = parseInt(e.key)
    if (!(isNaN(num)) && num >= 0 && num <= 9) { // Only allow numbers
      if (e.target.value.length >= 0) {
        setTimeout(() => focusNext(), 50)
      } else if (e.target.value.length >= 1) {
        e.preventDefault()
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcess('loading')

    // collect data from form
    const data = `${gv('nb1')}${gv('nb2')}${gv('nb3')}${gv('nb4')}${gv('nb5')}${gv('nb6')}`

    // trigger login action
    dispatch(verify(data, setAlert, navigate, lang, setProcess))
  }

  const handleResend = async (e) => {
    e.preventDefault()

    try {
      const { data } = await resendEmail()
      const msg_id = data?.msg_id
      dispatch(setAlert({ status: 'open', message: `${lang?.messages?.[msg_id]}${data?.email}` || 'Error: Something went wrong.'}))   
    } catch (error) {
      const error_id = error?.response?.data?.error_id
      dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))     
    }

  }

  return (
    <div ref={authVerify} id='auth-verify'>
      <main ref={form}>
        <h1>{lang?.h?.auth_verify}</h1>
        <p>{lang?.p?.auth_verify[0]}</p>
        <form onSubmit={handleSubmit}>
          <InputRow >
            <Lined>
              <input type='number' className='nb-input' id='nb1' min={0} max={9} onKeyDown={handleKeyDown}/>
            </Lined>
            <input type='number' className='nb-input' id='nb2' min={0} max={9} onKeyDown={handleKeyDown}/>
            <input type='number' className='nb-input' id='nb3' min={0} max={9} onKeyDown={handleKeyDown}/>
            <Lined mobonly>
              <input type='number' className='nb-input' id='nb4' min={0} max={9} onKeyDown={handleKeyDown}/>
            </Lined>
            <input type='number' className='nb-input' id='nb5' min={0} max={9} onKeyDown={handleKeyDown}/>
            <input type='number' className='nb-input' id='nb6' min={0} max={9} onKeyDown={handleKeyDown}/>

            <FormButton id='auth-button' orange text={lang?.form_buttons?.auth_verify} state={process}/>
          </InputRow>
        </form>
        <p>{lang?.p?.auth_verify[1]}<button onClick={handleResend}>{lang?.p?.auth_verify[2]}</button></p>
        <p>{lang?.p?.verify_later}<DelayLink to='/profile' onDelayStart={() => authVerify.current.classList.add('out')}>{lang?.kw?.skip_to_profile}</DelayLink></p>

      </main>

      <p className='copyrights'>{lang?.p?.copyrights}</p>

      <div ref={line1} className="line v-line" id="v-line-1"></div>
      <div ref={line2} className="line v-line" id="v-line-2"></div>
      <div className="line v-line" id="v-line-3"></div>
    </div>
  )
}

export default Verify
