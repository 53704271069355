export const formatDate = (date) => {
    date = new Date(date); // Convert the input date string to a Date object
    const year = date.getUTCFullYear(); // Get the year in UTC
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get the month in UTC and format it with leading zeros
    const day = String(date.getUTCDate()).padStart(2, '0'); // Get the day in UTC and format it with leading zeros
    return `${year}-${month}-${day}`; // Return the formatted date string in UTC
}

export const getAge = (date) => {
    if (date) {
        const today = new Date()
        const bd = new Date(date)
        let age = new Date().getFullYear() - bd.getFullYear()    
        const m = new Date().getMonth() - bd.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < bd.getDate())) {
            age--;
        }

        return age
    } 
}

export const dna = (value) => {
    return value === 'dna' || value === null || value === undefined ? '-' : value
}