import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'

import logoutIcon from '../../../assets/images/icons/logout.png'
import Basics from './Basics/Basics'
import Masks from './Masks/Masks'
import Team from './Team/Team'
import FatModal from '../../modals/SportsModal/SportsModal'
import Sports from './Sports/Sports'
import noPicture from '../../../assets/images/noPicture.png'
import OrgCell from './Sports/OrgCell'

import { logout } from '../../../actions/auth'
import { setNav } from '../../../actions/nav'
import { getMyProfile } from '../../../actions/profile'
import { SERVER_URL } from '../../../data/env'
import { dna } from '../../../utilities/formats'
import { setAlert } from '../../../actions/alert'
import { getMyOrg } from '../../../actions/org'
import { getMyTeams } from '../../../actions/teams'

const exists = (value) => dna(value) !== '-'

const Profile = () => {
    const { hash } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const pp = useRef(null)
    const scrollRef = useRef(null)
    const profile = useRef()

    const lang = useSelector((state) => state.lang)
    const p = useSelector((state) => state.profile)
    const auth = useSelector((state) => state.auth)

    const [maskView, setMaskView] = useState('mine')
    const [sports, setSports] = useState(false)
    const [mod, setMod] = useState(false)

    const handleLogout = () => {
        dispatch(logout(navigate))
    }

    // on mount
    useEffect(() => {
        // fetch profile document
        dispatch(getMyProfile(lang))
        if (auth?.user?.acc_type === 'coach') dispatch(getMyOrg())
        dispatch(getMyTeams(auth?.user?._id))

        // reset scroll to 0
        document.getElementById('root').scrollTo(0, 0)

        // animation in
        setTimeout(() => {
            profile.current.style.display = 'block'
        }, 200)
        setTimeout(() => {
            profile.current.style.setProperty('--animation-delay', '0s')
        }, 2000)

        // setting currentPage to home and navbar to nav1
        dispatch(setNav('profile'))
    }, [dispatch])

    useEffect(() => {
        if (pp.current) {
            pp.current.style.backgroundImage = `url("${SERVER_URL}/images/${p?.picture}")`   
        }
    }, [p])

    useEffect(() => {
        // go to orgadmin is auth is admin
        if (auth?.user?.acc_type === 'admin') navigate('/admin', { replace: true })
        
        // scroll if url has #masks
        if (hash === '#masks') {
            setTimeout(() => scrollRef.current.scrollIntoView({ behavior: 'smooth'}), 1310)
        }
    }, [])

    // remind user to verify account
    useEffect(() => {
        if (auth && auth?.user?.verified !== 'verified') {
            setTimeout(() => {
                dispatch(setAlert({ 
                    status: 'open', 
                    message: lang?.messages?.[1],
                    labels: { yes: lang?.buttons?.verify, no: lang?.buttons?.later},
                    yesno: true,
                    onYes: () => navigate('/verify')
                }))
            }, 2000)
        }
    }, [auth])

    return (
        <div id='profile' ref={profile}>
            <div className="header">
                <h1 id='tag'>#{p?.axid}</h1>

                <div className="line h-line" id='header-line'></div>
            </div>

            <main>
                {/* row 1 */}
                <div className="row-1">
                    <div className="picture">
                        {p?.picture 
                        ?   
                            <Link to='settings' ref={pp} alt='profile'></Link>
                        :
                            <Link to='settings' id="no-picture">
                                <img src={noPicture} alt="" />    
                            </Link>}
                    </div>
                    <Link to='settings' className="infos">
                        <div className='infos-header'>
                            <h2>{p?.firstname} {p?.lastname}</h2>
                            <p className='sub'>{auth.user?.acc_type}</p> 
                        </div>

                        {[p?.gender, p?.height?.[0], p?.weight, p?.birth_date, p?.birth_place, p?.residency].some(exists)
                        ?  
                            <Basics />
                        : 
                            <button className="edit-overlay">
                                {lang?.buttons?.add_basics}
                            </button>
                        }
                    </Link>

                    {p?.acc_type === 'athlete' 
                    ?
                    <Sports sports={p?.sports}/>
                    :
                    <OrgCell />
                    }
                    

                    <div className="line h-line" id="row-1-line"></div>
                </div>

                {/* row 2 */}
                <div className="row-2">
                    <button className={maskView === 'mine' ? 'active' : ''} onClick={() => { setMaskView('mine'); scrollRef.current.scrollIntoView({ behavior: 'smooth'}) }}>{lang?.kw?.my_masks}</button>
                    <button className={maskView === 'team' ? 'active' : ''} onClick={() => setMaskView('team')}>{lang?.kw?.my_teams}</button>

                    <div className="line h-line" id="row-2-line"></div>
                    <div className="line v-line" id="row-2-v-line"></div>                
                </div>

                <div className="scroll-ref" ref={scrollRef}></div>
                {maskView === 'mine' 
                    ?
                    <Masks />
                    :
                    <Team />
                }
            </main>

            <div className="logout">
                <button onClick={handleLogout}>{lang?.buttons?.logout} <img src={logoutIcon} alt="logout" height={12}/></button>

                <div className="line h-line" id='footer-line'></div>
            </div>
            
            <div className="line v-line" id='left-line'></div>
            <div className="line v-line" id='right-line'></div>

            <Outlet context={[setSports, setMod]}/>
            {sports && <FatModal mod={mod} close={() => setSports(false)} />}
        </div>
    )
}

export default Profile
