import React from 'react'
import './styles.css'

const InputRow = ({ children, ...rest }) => {

    const names = ['one', 'two', 'three', 'four', 'five', 'six', 'seven']

    return (
        <div className={`input-row ${names[children.length - 1]}`} {...rest}>
            {children}
        </div>
    )
}

export default InputRow
