import React, { useState, useEffect, useRef } from 'react'
import './styles.css'
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Input from '../../../tools/Input/Input'
import DDInput from '../../../tools/DDInput/DDInput'
import FileInput from '../../../tools/FileInput/FileInput'
import HeightInput from '../../../tools/Input/HeightInput'
import TextArea from '../../../tools/TextArea/TextArea'
import CenterModal from '../../../modals/CenterModal/CenterModal'

import { formatDate } from '../../../../utilities/formats'
import { val } from '../../../../utilities/functions'

import { setAlert } from '../../../../actions/alert'
import { setBasics } from '../../../../actions/profile'
import StateButton from '../../../tools/StateButton/StateButton'
import { useOutsideAlerter } from '../../../../utilities/hooks'
import Sport from './Sport'
import { joinOrg, leaveOrg } from '../../../../actions/org'
import { createTeam, joinTeam, leaveTeam } from '../../../../actions/teams'


const Settings = () => {
    const { hash } = useLocation()
    const [ setSports, setMod ] = useOutletContext()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [editing, setEditing] = useState('none')
    const [height, setHeight] = useState([0, 0])
    const [buttonStatus, setButtonStatus] = useState('initial')
    const [reset, setReset] = useState(false)
    const [joinForm, setJoinForm] = useState(false)
    const [teamsForm, setTeamsForm] = useState([false, 'create'])
    const [affCode, setAffCode] = useState([false, ''])

    const panel = useRef(null)
    const scrollRef = useRef(null)
    const general = useRef()
    useOutsideAlerter(general, () => cancelEditing())
    useOutsideAlerter(panel, () => navigate('/profile'))

    const lang = useSelector((state) => state.lang)
    const auth = useSelector((state) => state.auth)
    const p = useSelector((state) => state.profile)
    const org = useSelector((state) => state.org)
    const teams = useSelector((state) => state.teams)

    const handleBasics = (e) => {
        e.preventDefault()

        let data

        if (p?.acc_type === 'athlete') {
            data = { 
                birth_date: val('birth_date'),
                gender: val('gender'), 
                height,
                weight: val('weight'),
                birth_place: val('birth_place'),
                residency: val('residency') 
            }
        } else if (p?.acc_type === 'coach') {
            data = { 
                birth_date: val('birth_date'),
                gender: val('gender'), 
                birth_place: val('birth_place'),
                residency: val('residency'), 
                certifications: val('certifications')
            }
        }

        setButtonStatus('loading')
        dispatch(setBasics(data, setButtonStatus, lang, setEditing))
    }

    const cancelEditing = () => {
        setEditing('none')
        setReset(true)
    }
    
    const openSports = (isMod) => {
        isMod ??= false
        setMod(isMod)
        setSports(true)
    }

    const editBasics = (e) => {
        e.stopPropagation()

        if (editing === 'general') {
            cancelEditing()
        } else {
            setEditing('general')
        }
    }

    const handleJoinOrg = (e) => {
        e.preventDefault()

        setButtonStatus('loading')

        const adminData = { 
            adminId: auth?.user?._id,
            accType: auth?.user?.acc_type
        }

        const id = document.getElementById('join-org').value
        if (!id.length || id.length <= 0) 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[4] }))
        
        dispatch(joinOrg(id, adminData, lang, setButtonStatus))
    }

    const handleLeaveOrg = () => {
        // check if still is linked to teams
        if (teams?.length > 0) {
            return dispatch(setAlert({
                status: 'open',
                message: `${lang?.p?.leave_teams_before_org?.[0]} ${org?.name}${lang?.p?.leave_teams_before_org?.[1]}`
            }))
        }
        
        const adminData = { 
            adminId: auth?.user?._id,
            accType: auth?.user?.acc_type
        }
        
        dispatch(setAlert({ 
            status: 'open',
            message: lang?.p?.you_sure_leave,
            labels: { yes: lang?.buttons?.stay, no: lang?.buttons?.leave },
            yesno: true,
            onNo: () => dispatch(leaveOrg(org?._id, adminData, lang))
        }))

    }

    const handleCreateTeam = (e) => {
        e.preventDefault()
        
        if (p?.tests?.status === 'todo') 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[25] }))

        const data = {
            coachId: auth?.user?._id,
            tests: p?.tests,
            teamName: document.getElementById('team-input').value,
            orgId: org?._id
        }
        console.log(data)

        setButtonStatus('loading')
        dispatch(createTeam(data, lang, setButtonStatus, setTeamsForm))
    }

    const handleJoinTeam = (e) => {
        e.preventDefault()
        
        if (p?.tests?.status === 'todo') 
        return dispatch(setAlert({ status: 'open', message: lang?.errors?.[25] }))

        const affCode = document.getElementById('team-input').value
        console.log(affCode)

        setButtonStatus('loading')
        dispatch(joinTeam(affCode, p?.tests, lang, setButtonStatus, setTeamsForm))
    }

    const handleLeaveTeam = (team) => {        
        // warn of team deletion if last coach
        if (auth?.user?.acc_type === 'coach' && (!team?.coaches?.length || team?.coaches?.length < 2)) {
            dispatch(setAlert({ 
                status: 'open',
                message: lang?.p?.last_team_coach,
                labels: { yes: lang?.buttons?.stay, no: lang?.kw?.i_understand },
                yesno: true,
                onNo: () => dispatch(setAlert({ 
                    status: 'open',
                    message: lang?.p?.you_sure_leave,
                    labels: { yes: lang?.buttons?.stay, no: lang?.buttons?.leave },
                    yesno: true,
                    onNo: () => dispatch(leaveTeam(team?._id))
                }))
            }))
        } else {
            // make sure they really want to leave
            dispatch(setAlert({ 
                status: 'open',
                message: lang?.p?.you_sure_leave,
                labels: { yes: lang?.buttons?.stay, no: lang?.buttons?.leave },
                yesno: true,
                onNo: () => dispatch(leaveTeam(team?._id))
            }))
        }
    }

    useEffect(() => {
        if (hash === '#sports') {
            setTimeout(() => scrollRef.current.scrollIntoView({ behavior: 'smooth'}), 310)
        }
    }, [])

    return (
        <>
        <div id='settings'>
            <div className='panel' ref={panel}>
                <div className="panel-header">
                    <h2>{lang?.h?.settings_h2}</h2>  
                    <Link to='/profile'>{lang?.buttons?.close}</Link>
                    
                </div>

                <div className="block">
                    <div className="block-header">
                        <h3>{lang?.h?.settings_h3?.[0]}</h3>
                    </div>

                    <FileInput label={lang?.inputs?.profile_picture}/>
                </div>

                <form className="block" onSubmit={handleBasics} onMouseDown={() => setEditing('general')} ref={general}>
                    <div className="block-header">
                        <h3>{lang?.h?.settings_h3?.[1]}</h3>
                        <button type='button' onMouseDown={editBasics}>{editing === 'general' ? lang?.buttons?.cancel : lang?.buttons?.edit}</button>
                    </div>

                    <Input disabled={editing === 'general' ? false : true} type='date' id='birth_date' label={lang?.basics?.birth_date} defaultValue={formatDate(p?.birth_date)}  reset={{ reset, setReset }}/>
                    <DDInput disabled={editing === 'general' ? false : true} id='gender' label={lang?.basics?.gender} defaultValue={p?.gender} options={lang?.inputs?.gender} simple  reset={{ reset, setReset }}/>
                    {p?.acc_type === 'athlete' &&
                    <>
                    <HeightInput id='height' label={lang?.basics?.height} defaultValue={p?.height} disabled={editing === 'general' ? false : true} setHeight={setHeight} reset={{ reset, setReset }}/>
                    <Input disabled={editing === 'general' ? false : true} id='weight' type='number' label={lang?.basics?.weight} defaultValue={p?.weight} reset={{ reset, setReset }}/>
                    </>
                    }
                    <Input disabled={editing === 'general' ? false : true} id='birth_place' label={lang?.basics?.birth_place} defaultValue={p?.birth_place} reset={{ reset, setReset }}/>
                    <Input disabled={editing === 'general' ? false : true} id='residency' label={lang?.basics?.residency} defaultValue={p?.residency} reset={{ reset, setReset }}/>
                    {p?.acc_type === 'coach' &&
                    <TextArea id='certifications' label='Certifications' max={100} reset={{ reset, setReset }} defaultValue={p?.certifications}/>
                    }

                    {editing === 'general' && <StateButton type='submit' orange state={buttonStatus} initial={lang?.buttons?.save} success={lang?.buttons?.saved} onClick={handleBasics} />}
                </form>
                
                {p?.acc_type === 'athlete' && 
                <>
                <div className="block">
                    <div className="block-header">
                        <h3>{lang?.h?.settings_h3?.[2]}</h3>
                    </div>

                    {p?.sports && p?.sports?.length > 0 && p.sports.map((s, i) => {
                        return (
                            <Sport s={s} i={i} key={i} openSports={openSports} />
                        )
                    })}

                    {p?.sports && p?.sports?.length < 2 
                    ?
                    <button className='border-button orange full' onClick={openSports} >{lang?.buttons?.add_sport}</button>
                    :
                    <p>{lang?.p?.max_sports_reached}</p>
                    }
                </div>
                </>
                }
                {p?.acc_type === 'coach' &&
                <>
                {/* organization block */}
                <div className="block">
                    <div className="block-header">
                        <h3>{lang?.h?.settings_h3?.[3]}</h3>
                    </div>

                    {org 
                    ?
                    <ul className="sport org">
                        <h4>{org?.name}</h4>
                        
                        <div className="buttons">
                            <button onClick={handleLeaveOrg}>{lang?.buttons?.leave}</button>
                        </div>

                    </ul>
                    :
                    <>
                    {joinForm ?
                    <div className="no-org card-01">
                        <form className="join-card" onSubmit={handleJoinOrg}>
                            <h4>{lang?.kw?.join_your_org}</h4>
                            <Input id='join-org' label={lang?.kw?.affiliation_code} />
                            <StateButton state={buttonStatus} initial={lang?.buttons?.join} success={lang?.buttons?.saved} />
                            <button className='mt-75' onClick={() => setJoinForm(false)}>{lang?.buttons?.cancel}</button>
                        </form>
                    </div>
                    :
                    <button className='border-button full' onClick={() => setJoinForm(true)}>{lang?.kw?.join_your_org}</button>
                    }
                    </>
                    }
                </div>
                </>
                }

                {/* teams block */}
                <div className="block">
                    <div className="block-header">
                        <h3>{lang?.kw?.teams}</h3>
                    </div>

                    {((auth?.user?.acc_type === 'coach' && org) || auth?.user?.acc_type === 'athlete')
                    ? 
                    <>
                    {teams && teams?.length > 0 &&
                    teams.map((team, ind) => {
                        return (
                            <div className="team-card" key={ind}>
                                <h4>{team?.name}</h4>

                                <div className="buttons">
                                    {auth?.user?.acc_type === 'coach' && <button onClick={() => setAffCode([true, teams[ind]._id])}>{lang?.kw?.show_aff_code}</button>}
                                    <button onClick={() => handleLeaveTeam(teams?.[ind])}>{lang?.buttons?.leave}</button>
                                </div>
                            </div>
                        )
                    })
                    }
                    {teamsForm?.[0] ?
                    <div className="no-org card-01">
                        <form className="join-card" onSubmit={teamsForm?.[1] === 'create' ? handleCreateTeam : handleJoinTeam}>
                            <h4>{teamsForm?.[1] === 'create' ? lang?.kw?.create_team : lang?.kw?.join_a_team}</h4>
                            <Input id='team-input' label={teamsForm?.[1] === 'create' ? lang?.kw?.team_name : lang?.kw?.affiliation_code} />
                            <StateButton state={buttonStatus} initial={teamsForm?.[1] === 'create' ? lang?.buttons?.create : lang?.buttons?.join} success={lang?.buttons?.saved} />
                            <button className='mt-75' onClick={() => setTeamsForm([false, ''])}>{lang?.buttons?.cancel}</button>
                        </form>
                    </div>
                    :
                    <>
                        {auth?.user?.acc_type === 'coach' && <button className='border-button full' onClick={() => setTeamsForm([true, 'create'])}>{lang?.kw?.create_team}</button>}
                        <button className='border-button full' onClick={() => setTeamsForm([true, 'join'])}>{lang?.kw?.join_a_team}</button>
                    </>
                    }
                    </>
                    :
                    <p>To create or join a team, you must first join an organization.</p>
                    }                    
                </div>
                <div className="scroll-ref" ref={scrollRef}></div>
            </div> 
        </div>
        {affCode?.[0] && 
        <CenterModal closeModal={() => setAffCode([false, ''])}>
            <h2 className='dont'>{lang?.p?.aff_code_helper_team?.[0]}</h2>
            <p className='dont'>{lang?.p?.aff_code_helper_team?.[1]}</p>
            <p className='dont'>{lang?.p?.aff_code_helper_team?.[2]}</p>
            <p className='fs-accent dont'>{affCode?.[1]}</p>
        </CenterModal>
        }
        </>
    )
}


export default Settings