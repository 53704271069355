import * as API from '../api/index'
import * as actions from '../data/actions_types'

import { setAlert } from './alert'

export const createTeam = (_data, lang, setStatus, setTeamsForm) => async (dispatch) => {
    try {
        const { data } = await API.createTeam(_data)

        setStatus('initial')
        setTeamsForm([false, 'create'])

        dispatch({ type: actions.CREATE_TEAM, payload: data })
    } catch (error) {
        setStatus('initial')
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

export const getMyTeams = (userId) => async (dispatch) => {
    try {
        const { data } = await API.getMyTeams(userId)

        dispatch({ type: actions.SET_TEAMS, payload: data })  
    } catch (error) {
        console.log(error)
    }
}

export const joinTeam = (teamId, tests, lang, setStatus, setTeamsForm, handleCancelJoin) => async (dispatch) => {
    try {
        const { data } = await API.joinTeam(teamId, tests)

        if (setTeamsForm !== 'athlete')  {
            setStatus('initial')
            setTeamsForm([false, 'create'])
        } 
        else {
            setStatus('success')
            handleCancelJoin()
        }

        dispatch({ type: actions.SET_TEAM, payload: data })
    } catch (error) {
        setStatus('initial')
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}

export const leaveTeam = (teamId, lang) => async (dispatch) => {
    try {
        const { data } = await API.leaveTeam(teamId)

        console.log('Response: ', data)

        dispatch({ type: actions.LEAVE_TEAM, payload: data })
    } catch (error) {
        console.log(error)
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
} 