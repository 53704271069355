import * as actions from '../data/actions_types'
import * as API from '../api/index'
import { setAlert } from './alert'

// get full profile
export const getMyProfile = (lang) => async (dispatch) => {
    try {
        const { data } = await API.getMyProfile() 

        dispatch({ type: actions.SET_PROFILE, payload: data })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
}



// upload profile picture
export const uploadProfilePicture = (formData, axid, lang, setState) => async (dispatch) =>{
    try {
        const { data } = await API.uploadProfilePicture(formData, axid)

        dispatch({ type: actions.SET_PROFILE_PICTURE, payload: data })

        setState('saved')
        setTimeout(() => setState('initial'), 1000)
    } catch (error) {
        console.log(error)
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.'}))
    }
} 



// update basics
export const setBasics = (_data, setStatus, lang, setEditing) => async (dispatch) => {
    try {
        const { data } = await API.setBasics(_data)

        dispatch({ type: actions.SET_PROFILE, payload: data })
        setStatus('success')
        setTimeout(() => {
            setStatus('initial')
            setEditing('none')
        }, 1500)
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        setStatus('initial')
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.' }))
    }
}



// add sport
export const addSport = (_data, setStatus, lang) => async (dispatch) => {
    try {
        const { data } = await API.addSport(_data)

        dispatch({ type: actions.SET_PROFILE, payload: data })
        setStatus('success')
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        setStatus('initial')
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.' }))
    }
}

// update sport
export const updateSport = (_data, ind, setStatus, lang) => async (dispatch) => {
    try {
        const { data } = await API.updateSport(_data, ind)

        dispatch({ type: actions.SET_PROFILE, payload: data })
        setStatus('success')
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        setStatus('initial')
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.' })) 
    }
}

// delete sport
export const deleteSport = (ind, lang) => async (dispatch) => {
    try {
        const { data } = await API.deleteSport(ind)

        dispatch({ type: actions.SET_PROFILE, payload: data })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.' }))
    }
}

// personality tests
export const setTests = (_data, lang) => async (dispatch) => {
    try {
        const { data } = await API.setTests(_data)

        dispatch({ type: actions.SET_PROFILE, payload: data })
    } catch (error) {
        const error_id = error?.response?.data?.error_id
        dispatch(setAlert({ status: 'open', message: lang?.errors?.[error_id] || 'Error: Something went wrong.' }))
    }
}