import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import { deleteSport } from '../../../../actions/profile'

import { dna } from '../../../../utilities/formats'

const Sport = ({ s, i, openSports }) => {
    const dispatch = useDispatch()

    const lang = useSelector((state) => state.lang)
    const eng = useSelector((state) => state.lang.name) === 'eng' ? true : false
    
    const [open, setOpen] = useState(false)

    const handleDelete = (i) => {
        console.log(i)

        dispatch(deleteSport(i, lang))
    }

    return (
        <ul className="sport" key={i} onClick={() => setOpen(prev => !prev)}>
            <h4>{eng ? s?.sport?.name_eng : s?.sport?.name_fr}</h4>
            
            <li><p>{lang?.kw?.position}</p> <p>{eng ? dna(s?.specialty_eng) : dna(s?.specialty_fr)}</p></li>
            <li><p>{lang?.kw?.category}</p> <p>{dna(s?.category)}</p></li>
            <AnimatePresence>
                {open && (<motion.div 
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1, transition: {
                        type: 'tween',
                        duration: 0.3,
                        ease: 'easeInOut'
                    } }}
                    exit={{ height: 0, opacity: 0, transition: {
                        type: 'tween', duration: 0, ease: 'easeInOut'
                    } }}
                    className="buttons"
                >
                    <button onClick={() => openSports(i)}>{lang?.buttons?.edit}</button>
                    <button onClick={() => handleDelete(i)}>{lang?.buttons?.delete}</button>
                </motion.div>)}
            </AnimatePresence>
        </ul>
    )
}

export default Sport
