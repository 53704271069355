import { SET_ALERT, SET_ALERT_MESSAGE, SET_ALERT_STATUS } from '../data/actions_types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (alert = { status: 'closed', message: 'Error message not found', onClose: null }, action) => {
    switch (action.type) {
        case SET_ALERT:
            return action?.payload
        case SET_ALERT_MESSAGE:
            return { message: action?.payload, status: alert.status, onClose: alert.onClose }
        case SET_ALERT_STATUS: 
            return { status: action?.payload, message: alert.message, onClose: alert.onClose }
        default:
            return alert
    }
}